/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import brandLogo from '../../assets/images/logo.png';
import './styles.scss';

import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { sendOtp } from './loginApi';
import eyeShow from '../../assets/Img/eye-show.svg';
import eyeHide from '../../assets/Img/eye-hide.svg';

const LoginComponent = (props) => {
  const dispatch = useDispatch();
  const [LoginValue, setLoginValue] = useState({
    email: '',
    password: ''
  });
  const [isLoading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      navigate('/accounts'); // Redirect to accounts if token exists
    }
  }, [navigate]);
  const onHandleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = LoginValue;
    if (!email || !password) {
      message.error('Please fill in all fields', 1.5);
      setHasChanges(true);
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      message.error('Invalid email address', 1.5);
      setHasChanges(true);
      return;
    }
    try {
      setLoading(true);
      let response = await dispatch(sendOtp(LoginValue));

      if (response.error) {
        message.error(response?.error?.message, 1.5);
        setErrorMessage('');
        setLoading(false);
      } else if (response?.payload?.accounts) {
        navigate('/accounts');

        setErrorMessage('');
        setLoading(false);
      } else {
        navigate('/otp', { state: { userValue: LoginValue } });
        setErrorMessage('');
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message, 1.5);
      console.log('Error signing in:', error);
    }
  };

  const handleInputChange = (e) => {
    setErrorMessage('');
    setHasChanges(true);
    const { name, value } = e.target;
    setLoginValue({ ...LoginValue, [name]: value });
  };
  return (
    <>
      <div className="login-wrapper">
        <div className="login-container">
          <div className="loginBlock">
            <div className="brandLogo">
              <img src={brandLogo} alt="Brand Logo" />
            </div>
            <form className="login-form" onSubmit={onHandleSubmit}>
              <div className="sign-title">Sign In</div>
              <div className="pb-3">
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  name="email"
                  value={LoginValue.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="pb-3">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    className="form-control"
                    name="password"
                    value={LoginValue.password}
                    onChange={handleInputChange}
                    style={{ paddingRight: '42px' }}
                  />
                  <button
                    type="button"
                    className="password-toggle-btn"
                    style={{
                      border: 'none',
                      width: '50px',
                      height: '35px',
                      cursor: 'pointer',
                      marginLeft: '-50px',
                      fontSize: '16px',
                      background: 'none'
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <img alt="logo" src={showPassword ? eyeShow : eyeHide} />
                  </button>
                </div>
              </div>
              <div className="pb-3">
                <a
                  href="/forgotpassword"
                  className="link_color"
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  Forgot password?
                </a>
              </div>
              <div className="py-3">
                <button
                  className="btn btn-primary btn-sm w-100"
                  // disabled={isLoading}
                  disabled={!hasChanges || isLoading}
                  type="submit"
                >
                  {isLoading ? 'Please wait..' : 'Sign In'}
                </button>
              </div>
              <div className="pb-3">
                <p>
                  Don't have an account?{' '}
                  <a
                    onClick={() => {
                      navigate('/create-account');
                    }}
                    className="link_color cursor-pointer"
                  >
                    Create one
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginComponent;
