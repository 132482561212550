import * as React from 'react';

const Logo = ({ ...props }) => {
  return (
    <svg
      width="45"
      height="50"
      viewBox="0 0 45 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_2655_991)">
        <path
          d="M39.2996 0H5.25292C2.33463 0 0 2.33463 0 5.05837V44.7471C0 46.1089 0.583658 47.4708 1.55642 48.4436C2.91829 49.8054 4.47471 50 5.25292 50H32.2957L44.5525 36.5759V5.05837C44.358 2.33463 42.0233 0 39.2996 0ZM31.7121 46.109L31.5175 35.9922H40.856L31.7121 46.109ZM41.4397 33.0739H31.5175C29.9611 33.0739 28.7938 34.4358 28.7938 35.9922V46.8872H5.25292C5.05837 46.8872 4.28016 46.8872 3.6965 46.3035C3.30739 45.9144 3.11284 45.3307 3.11284 44.7471V5.05837C2.91829 3.89105 3.89105 2.91829 5.25292 2.91829H39.2996C40.4669 2.91829 41.6342 3.89105 41.6342 5.05837V33.0739H41.4397Z"
          fill="#2D9864"
        />
        <path
          d="M32.6853 15.9534H11.6736C10.8954 15.9534 10.1172 15.3698 10.1172 14.397C10.1172 13.4243 10.8954 13.0352 11.6736 13.0352H32.8798C33.658 13.0352 34.4363 13.6188 34.4363 14.5916C34.4363 15.5643 33.658 15.9534 32.6853 15.9534Z"
          fill="#2D9864"
        />
        <path
          d="M32.6853 23.9302H11.6736C10.8954 23.9302 10.1172 23.3466 10.1172 22.3738C10.1172 21.401 10.7008 20.8174 11.6736 20.8174H32.8798C33.658 20.8174 34.4363 21.401 34.4363 22.3738C34.4363 23.3466 33.658 23.9302 32.6853 23.9302Z"
          fill="#2D9864"
        />
        <path
          d="M27.6269 31.7124H11.6736C10.8954 31.7124 10.1172 31.1288 10.1172 30.156C10.1172 29.1833 10.7008 28.5996 11.6736 28.5996H27.6269C28.4051 28.5996 29.1833 29.1833 29.1833 30.156C29.1833 31.1288 28.4051 31.7124 27.6269 31.7124Z"
          fill="#2D9864"
        />
      </g>
      <defs>
        <clipPath id="clip0_2655_991">
          <rect width="44.358" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
