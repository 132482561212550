import * as React from 'react';

const Logo = ({ ...props }) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  {...props}>
      <g data-name="76.Sad">
        <path fill="currentColor" d="M12 24a12 12 0 1112-12 12.013 12.013 0 01-12 12zm0-22a10 10 0 1010 10A10.011 10.011 0 0012 2z"></path>
        <path fill="currentColor" d="M16 11a3 3 0 01-3-3h2a1 1 0 002 0h2a3 3 0 01-3 3zM8 11a3 3 0 01-3-3h2a1 1 0 002 0h2a3 3 0 01-3 3zM16.377 17.342c-.047-.036-4.62-3.608-9.006-1.853l-.742-1.858c5.473-2.19 10.773 1.97 11 2.148z"></path>
      </g>
    </svg>
  );
};

export default Logo;
