import React, { useState, useEffect, memo } from 'react';
import { Pagination, DatePicker } from 'antd';
import moment from 'moment';

import './styles.scss';
import { forceUnaryOperator } from 'utils/numbers';
import {
  // dateDifference,

  getFormattedDateTime
} from 'utils/date';
import { TradeSide } from 'models/enums/tradeSide';
import { useSelector } from 'app/store';
import Loader from '../loader/loader';

export enum FilterType {
  DATE = 'date',
  STRING = 'string'
}

export type DateRange = {
  end: string;
  start: string;
};

export type FilterValue<T extends FilterType> = T extends FilterType.DATE
  ? DateRange
  : string;

export type TradeFilters =
  | {
      [FilterType.DATE]?: DateRange;
      [FilterType.STRING]?: string;
    }
  | undefined;

const addLineBreakInDateTime = true;

type Props = {
  accountNo: string;
  appliedFilters?: TradeFilters;
  onRowPress: (trade: any) => void;
  table?: any[];
};

const TradeTable = ({
  accountNo,
  appliedFilters,
  onRowPress,
  table
}: Props) => {
  const { jwt } = useSelector((s) => s.login);

  const [trades, setTrades] = useState<any[]>([]);

  const [totalTrades, setTotalTrades] = useState(0);
  const [page, setPage] = useState({ page: 1, limit: 10 });

  useEffect(() => {
    const fetchTrades = async () => {
      if (table && table.length > 0) {
        setTrades(table);
        setTotalTrades(table.length);
        return;
      }
    };

    fetchTrades();
  }, [table]);

  const { RangePicker } = DatePicker;
  const handlePaginationChange = (newPage, pageSize) => {
    setPage({ page: newPage, limit: pageSize });
  };

  const startIndex = (page.page - 1) * page.limit;
  const endIndex = startIndex + page.limit;
  const currentPageTrades = trades.slice(startIndex, endIndex);

  const calculateDuration = (startTime, endTime) => {
    const startMoment = moment(startTime);
    const endMoment = moment(endTime);
    const duration = moment.duration(endMoment.diff(startMoment));

    const months = endMoment.diff(startMoment, 'months');
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    const components = [
      { value: months, unit: 'M' },
      { value: days, unit: 'D' },
      { value: hours, unit: 'h' },
      { value: minutes, unit: 'm' }
    ];

    const filteredComponents = components.filter(
      (component) => component.value !== 0
    );

    const formattedDuration = filteredComponents
      .map((component) => `${component.value} ${component.unit}`)
      .join(', ');

    return formattedDuration === '' ? '--' : formattedDuration;
  };

  return (
    <>
      <div className="table-responsive table-wrapper p-0">
        <table className="table m-0">
          <thead>
            <tr>
              <th>Open</th>
              <th>Symbol</th>
              <th>Close</th>
              <th>Entry Price</th>
              <th>Exit Price</th>
              <th>Type</th>
              <th>Volume</th>
              {/* <th>Duration</th> */}
              <th>Net P & L</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentPageTrades.length === 0 ? (
              <tr>
                <td
                  colSpan={10}
                  style={{
                    textAlign: 'center',
                    height: '40vh',
                    position: 'relative'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%'
                    }}
                  >
                    <Loader />
                  </div>
                </td>
              </tr>
            ) : (
              currentPageTrades.map((trade) => (
                <tr key={trade._id} onClick={() => onRowPress(trade)}>
                  <td>
                    {getFormattedDateTime(
                      trade.tradeTime,
                      addLineBreakInDateTime
                    )}
                  </td>
                  <td>{trade.symbol}</td>
                  <td>
                    {getFormattedDateTime(
                      trade.closingTime,
                      addLineBreakInDateTime
                    )}
                  </td>
                  <td>{trade.openPrice}</td>
                  <td>{trade.currentPrice}</td>
                  <td>
                    <span
                      className={trade.type === TradeSide.BUY ? 'buy' : 'sell'}
                    >
                      {trade.type}
                    </span>
                  </td>
                  <td>{trade.volume}</td>
                  {/* <td>
                    {calculateDuration(trade.tradeTime, trade.closingTime)}
                  </td> */}

                  <td className={trade.currentProfit > 0 ? 'green' : 'red'}>
                    {forceUnaryOperator(trade.currentProfit)}
                  </td>
                  <td></td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {currentPageTrades.length !== 0 &&
        !appliedFilters?.[FilterType.STRING] &&
        totalTrades > 10 ? (
          <div className="d-flex justify-content-between align-items-center p-3">
            <p className="m-0" style={{ whiteSpace: 'nowrap' }}>
              {(page.page - 1) * page.limit + 1} to{' '}
              {Math.min(page.page * page.limit, totalTrades)} of {totalTrades}{' '}
              results
            </p>
            <Pagination
              total={totalTrades}
              onChange={handlePaginationChange}
              defaultPageSize={page.limit}
              current={page.page}
              className="table--pagination"
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
export default memo(TradeTable);
