import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <>
      <section className="error-404 not-found default-max-width">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="error-container-block">
                <h1 className="heading">404</h1>
                <div className="subtitle">
                  <p>
                    We're sorry, the page you requested could not be found
                    please go back to the homepage
                  </p>
                </div>
                <div className="error-btn">
                  <a
                    onClick={() => {
                      navigate('/accounts');
                    }}
                    className="btn btn-gredient-primary letter-spacing text-uppercase"
                  >
                    GO HOME
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
