import { DEFAULT_DECIMAL_SCALE } from '../constants';

export const forceUnaryOperator = (number: number | undefined) => {
  if (!number) return 0;
  if (number > 0) {
    return `+${number}`;
  }
  return number.toString();
};

export const formatPrice = (value, decimalPlaces = DEFAULT_DECIMAL_SCALE) => {
  if (isNaN(value)) {
    return '-';
  }

  if (typeof value !== 'number') value = Number(value);
  value = value.toFixed(decimalPlaces);

  const thousandsSeparator = ',';
  const decimalSeparator = '.';

  if (decimalPlaces == 0) {
    return `${value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)}`;
  } else {
    const [currency, decimal] = value.split('.');
    if (decimal == 0) {
      return `${currency.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)}`;
    } else {
      return `${currency.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        thousandsSeparator
      )}${decimalSeparator}${decimal}`;
    }
  }
};

export const beautifyUSDValue = (value, decimalPlaces = 0) => {
  if (isNaN(value)) {
    return '-';
  }

  if (typeof value !== 'number') value = Number(value);
  value = value.toFixed(decimalPlaces);

  const thousandsSeparator = ',';
  const decimalSeparator = '.';

  if (decimalPlaces == 0) {
    return `${value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)}`;
  } else {
    const [currency, decimal] = value.split('.');
    if (decimal == 0 || value >= 1000) {
      return `${currency.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)}`;
    } else {
      return `${currency.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        thousandsSeparator
      )}${decimalSeparator}${decimal}`;
    }
  }
};

export const formatPriceWithDollar = (
  value,
  decimalPlaces = DEFAULT_DECIMAL_SCALE
) => {
  const res = formatPrice(Math.abs(value), decimalPlaces);
  if (value < 0) return `-$${res}`;
  return `$${res}`;
};

export const formatVolumeWithDollar = (value, decimal = 1) => {
  const res = formatVolume(Math.abs(value), decimal);
  if (value < 0) return `-$${res}`;
  return `$${res}`;
};

export const formatQuantity = (value, decimalPlaces = 0) => {
  if (isNaN(value)) {
    return '-';
  }

  if (typeof value !== 'number') value = Number(value);
  value = value.toFixed(decimalPlaces);

  const decimalSeparator = '.';

  if (decimalPlaces == 0) {
    return `${value}`;
  } else {
    const [currency, decimal] = value.split('.');
    if (decimal == 0) {
      return `${currency}`;
    } else {
      return `${currency}${decimalSeparator}${decimal}`;
    }
  }
};

export const getDecimalPlaces = (minTick: number | undefined) => {
  if (!minTick) {
    return 0;
  }
  return Math.abs(Math.floor(Math.log10(minTick)));
};

const SI_SYMBOL = ['', 'K', 'M', 'B', 'T', 'P', 'E'];
export const formatVolume = (value, maxPostDecimal = 1) => {
  if (isNaN(value)) {
    return '-';
  }

  if (typeof value !== 'number') value = Number(value);
  value = value.toFixed(2);

  const tier = (Math.log10(Math.abs(value)) / 3) | 0;
  if (tier == 0)
    return mergeScaledAndSuffix(
      Number(value),
      '',
      Math.max(maxPostDecimal, DEFAULT_DECIMAL_SCALE)
    );
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = value / scale;

  return mergeScaledAndSuffix(
    scaled,
    suffix,
    tier === 1
      ? Math.max(maxPostDecimal, DEFAULT_DECIMAL_SCALE)
      : maxPostDecimal
  );
};

const mergeScaledAndSuffix = (
  scaled: number,
  suffix: string,
  maxPostDecimal: number
) => {
  const postDecimal = scaled % 1;
  // If we dont have a decimal point, return the scaled directly with suffix
  if (postDecimal === 0) return scaled + suffix;

  // If we do have a decimal point, check whether the the decimals points are more than the max accepted input
  if (postDecimal.toString().length > maxPostDecimal)
    return removeUnwantedPostDecimalZeros(scaled, maxPostDecimal) + suffix;

  return scaled + suffix;
};

// Removes extra zeros towards the end post decimal point as they dont mean anything
export const removeUnwantedPostDecimalZeros = (
  value: number | string,
  maxPostDecimal: number = 10
) => {
  let val = typeof value === 'number' ? value : Number(value);
  return val
    .toFixed(maxPostDecimal)
    .replace(/(\.\d*?[1-9])0+$/, '$1')
    .replace(/\.?0+$/, '');
};

export const isNumber = (value: any): value is number => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};

export const getValueOrZero = (value: number | undefined | null) => {
  if (!value || value <= 0) {
    return 0;
  }
  return value;
};

export const truncatePrice = (
  value: number | string | null,
  digits = 0
): number | null => {
  // Check if the value is a number or numeric string
  if (!isNumber(value) || value === null || value === undefined) {
    return null;
  }

  const parsedValue = Number(value);

  // Calculate the factor used for truncation
  const fact = 10 ** digits;
  // Truncate the parsed value, ensuring correct rounding behavior for negative numbers
  const truncatedValue =
    parsedValue >= 0
      ? Math.floor(parsedValue * fact)
      : Math.ceil(parsedValue * fact);

  // Return the truncated value divided by the factor
  return truncatedValue / fact;
};

export const basisToPercent = (basis: number | undefined): number | null => {
  if (basis === undefined) return null;
  return basis * 100;
};

export const delay = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};
