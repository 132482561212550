import { createSlice } from '@reduxjs/toolkit';
import { fetchTradeData, getCalculations } from './homeApi';

const initialState = {
  isLoading: false,
  data: null,
  calculation: {},
  loader: false,
  error: null,
  isError: false
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTradeData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchTradeData.rejected, (state, action) => {
      state.isError = action.error.message;
    });
    builder.addCase(getCalculations.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getCalculations.fulfilled, (state, action) => {
      state.loader = false;
      state.calculation = action.payload.calculation;
    });
    builder.addCase(getCalculations.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload.message;
    });
  }
});

export default homeSlice.reducer;
