import axios from 'axios';
import AppConfig from '../../config';

export const getAnalysisReport = async (account_id) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.get(AppConfig.apiUrl + '/api/get_report', {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json'
      },
      params: {
        account_id: account_id
      }
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;

    console.error('Error during sign in:', error);

    throw errorMessage;
  }
};

export const tradingPlanUpdate = async (
  updateTrandingPlanId,
  updateTradingPlan
) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      AppConfig.apiUrl + '/update_trading_plan',
      updateTradingPlan,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        },
        params: {
          account_id: updateTrandingPlanId
        }
      }
    );

    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;

    console.error('Error during sign in:', error);

    throw errorMessage;
  }
};

export const getUpcomingTrades = async (account_id) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios?.get(AppConfig?.apiUrl + '/upcoming_trades', {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json'
      },
      params: {
        // Use params instead of data for GET requests
        account_id: account_id
      }
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    console.error('Error during sign in:', error);
    throw errorMessage;
  }
};
