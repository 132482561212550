// Trading View Chart Library -1
import { createChart } from 'lightweight-charts';
import React, { useEffect, useRef } from 'react';
import BlankGraph from '../../assets/images/blankGraph.png';
import Loader from 'components/loader/loader';
export const ChartComponent = (props) => {
  const {
    stockDataLoading,
    stockData,
    colors: {
      backgroundColor = 'white',
      lineColor = '#2962FF',
      textColor = 'black',
      areaTopColor = '#2962FF',
      areaBottomColor = 'rgba(41, 98, 255, 0.28)'
    } = {}
  } = props;

  const data = stockData?.map((entry) => ({
    open: entry?.Open,
    high: entry?.High,
    low: entry?.Low,
    close: entry?.Close,
    time: entry.Datetime
      ? new Date(entry.Datetime).getTime() / 1000 // Extract time if Datetime is present
      : new Date(entry.Date).getTime() / 1000
  }));

  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return; // Skip chart creation if there's no data

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    // const chartOptions = {
    //   layout: {
    //     textColor,
    //     background: { type: 'solid', color: backgroundColor }
    //   },
    //   timeScale: {
    //     timeVisible: true,
    //     secondsVisible: false,
    //     tickMarkFormatter: (time, tickMarkType, locale) => {
    //       const date = new Date(time * 1000);
    //       const options = { day: '2-digit', month: 'short' };
    //       const hours = date.getHours().toString().padStart(2, '0');
    //       const minutes = date.getMinutes().toString().padStart(2, '0');
    //       const formattedDate = date.toLocaleDateString(locale, options);
    //       return `${formattedDate} ${hours}:${minutes}`;
    //     }
    //   },
    //   priceScale: {
    //     mode: 2,
    //     autoScale: true,
    //     borderVisible: true,
    //     borderColor: '#d1d4dc',
    //     alignLabels: true, // Ensures better alignment of labels
    //     tickMarkFormatter: (price) => price.toFixed(5) // Custom formatter for Y-axis labels
    //   },
    //   rightPriceScale: {
    //     scaleMargins: {
    //       top: 0.3,
    //       bottom: 0.25
    //     },
    //     borderVisible: false,
    //     priceFormat: {
    //       type: 'custom',
    //       formatter: (price) => price.toFixed(5)
    //     },
    //     drawTicks: true, // Ensures ticks are drawn
    //     alignLabels: true // Ensures labels are aligned
    //   },
    //   grid: {
    //     vertLines: {
    //       color: '#e1e3e6',
    //       visible: true
    //     },
    //     horzLines: {
    //       color: '#e1e3e6',
    //       visible: true
    //     }
    //   }
    // };
    const chartOptions = {
      layout: {
        textColor,
        background: { type: 'solid', color: backgroundColor }
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        tickMarkFormatter: (time) => {
          const date = new Date(time * 1000); // Convert UNIX timestamp to Date object
          const options = { 
            day: '2-digit', 
            month: 'short' 
          };
          const hours = date.getUTCHours().toString().padStart(2, '0'); // Use getUTCHours for UTC time
          const minutes = date.getUTCMinutes().toString().padStart(2, '0'); // Use getUTCMinutes for UTC time
          const formattedDate = date.toLocaleDateString('en-US', options); // Format date as Month Day
          return `${formattedDate} ${hours}:${minutes}`; // Return formatted date and time
        },
        tickMarkSkip: 0, // Ensure all ticks are shown
        borderColor: '#d1d4dc',
        borderWidth: 1
      },
      priceScale: {
        mode: 2,
        autoScale: true,
        borderVisible: true,
        borderColor: '#d1d4dc',
        alignLabels: true,
        tickMarkFormatter: (price) => price.toFixed(5)
      },
      rightPriceScale: {
        scaleMargins: {
          top: 0.3,
          bottom: 0.25
        },
        borderVisible: false,
        priceFormat: {
          type: 'custom',
          formatter: (price) => price.toFixed(5)
        },
        drawTicks: true,
        alignLabels: true
      },
      grid: {
        vertLines: {
          color: '#e1e3e6',
          visible: true
        },
        horzLines: {
          color: '#e1e3e6',
          visible: true
        }
      }
    };
    
    const chart = createChart(chartContainerRef.current, chartOptions);
    const candlestickSeries = chart.addCandlestickSeries({
      upColor: '#26a69a',
      downColor: '#ef5350',
      borderVisible: false,
      wickUpColor: '#26a69a',
      wickDownColor: '#ef5350'
    });

    candlestickSeries.setData(data);

    chart.timeScale().fitContent();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [
    data,
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor
  ]);
  if (stockDataLoading) {
    return (
      <div style={{ position: 'relative', minHeight: '50vh' }}>
        <Loader />
      </div>
    );
  } else if (stockData.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'auto'
        }}
      >
        <img
          src={BlankGraph}
          style={{ Width: '300px', maxWidth: '350px' }}
        ></img>
        <h5 style={{ textAlign: 'center', fontSize: '16px', padding: '15px' }}>
          No graph data to display
        </h5>
      </div>
    );
  }

  return (
    <div ref={chartContainerRef} style={{ width: '100%', height: '350px' }} />
  );
};

const CandlestickChart = (props) => {
  return <ChartComponent {...props}></ChartComponent>;
};

export default CandlestickChart;

// React-apexcharts Library -2
// import React from 'react';
// import Chart from 'react-apexcharts';
// const CandlestickChart = ({ stockData }) => {

// const formattedData = stockData?.map((item) => ({
//   x: new Date(item.Datetime).getTime(),
//   y: [
//     item.Open.toFixed(5),
//     item.High.toFixed(5),
//     item.Low.toFixed(5),
//     item.Close.toFixed(5)
//   ],
//   fillColor: item.Close > item.Open ? '#4CAF50' : '#F44336', // Green for positive, red for negative
//   strokeColor: item.Close > item.Open ? '#4CAF50' : '#F44336' // Same color for border
// }));

// const options = {
//   chart: {
//     type: 'candlestick',
//     height: 350,
//     toolbar: {
//       show: false, // Show the toolbar to enable zooming and panning
//       tools: {
//         zoom: false,
//         zoomin: false,
//         zoomout: false,
//         pan: false,
//         reset: false // Adds a reset button to reset the zoom and pan
//       }
//     },
//     zoom: {
//       enabled: false, // Enable zooming
//       type: 'xy'
//       // Zoom in both x and y directions
//     },
//     pan: {
//       enabled: false, // Enable panning
//       mode: 'xy' // Pan in both x and y directions
//     }
//   },
//   series: [
//     {
//       data: formattedData
//     }
//   ],
//   xaxis: {
//     type: 'datetime',
//     labels: {
//       format: 'dd MMM HH:mm',
//       datetimeFormatter: {
//         day: 'dd MMM HH:mm'
//       }
//     },
//     tickAmount: formattedData.length // Adjust this to match the number of data points
//   },
//   yaxis: {
//     tooltip: {
//       enabled: false // Enable tooltips on yAxis
//     },
//     labels: {
//       formatter: function (value) {
//         return value.toFixed(5); // Format open values to two decimal places
//       }
//     },
//     opposite: true // Display yAxis on the right side
//   },
//   plotOptions: {
//     candlestick: {
//       colors: {
//         upward: '#4CAF50', // Green color for upward candles
//         downward: '#F44336' // Red color for downward candles
//       }
//     }
//   }
// };

// return (
//   <div>
//     <Chart
//       options={options}
//       series={options.series}
//       type="candlestick"
//       height={350}
//     />
//   </div>
// );
// };

// export default CandlestickChart;
