import React from 'react';
import './styles.scss';
import { useLocation } from 'react-router-dom';
import Loader from 'components/loader/loader';

const Analysis = () => {
  const location = useLocation();
  const accountId = new URLSearchParams(location.search).get('account_id');

  return (
    <>
      <div className="initiateModalContainer">
        <div className="initiateModalBody">
          <h1>Data analysis activated for Account #{accountId}</h1>
          <div className="initiateModalLoader">
            <Loader />
          </div> 
          <p>
            Your trading history is in the lab! Hang tight, we’ll ping you as
            soon as it's ready. Once done, you'll unlock the full power of
            Fractal Alpha.
          </p>
         
        </div>
      </div>
    </>
  );
};

export default Analysis;
