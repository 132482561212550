import axios from 'axios';
import AppConfig from 'config';
import { RequestMethod } from '../models/enums/requestMethods';
import { BackendObj, ApiMapping } from '../interfaces';
import { deserializeArray, deserializeSingle } from 'utils/apiMiddleware';
import { FrontendSecrets } from 'models/frontEndSecrets';

export interface Pagination {
  total: number;
  page: number;
  perPage: number;
  limit: number;
  order: string;
}

// @Todo : Ask Anshul to follow a format to be consistent
interface FractalResponse<T> {
  error: string;
  data: T[] | T;
  success: boolean;
  pagination: Pagination;
}

export type RequestResponse<ResponseType> = {
  pagination: Pagination;
  data: ResponseType;
};

export type QueryParams = Record<string, number | string | string[]>;

interface RequestParams {
  method: RequestMethod;
  path?: string;
  fullUrl?: string;
  body?: any;
  queryParams?: QueryParams;
  headers?: { [key: string]: string };
  isArray?: boolean;
  returnRaw?: boolean;
  responseMapping?: ApiMapping;
  paramsMapping?: ApiMapping;
}
/**
 * The base http service
 */
export class RestService {
  private static instance: RestService;
  private apiUrl: string;

  constructor() {
    // @TODO: there should be an option to pass in apiUrl through a constructor, as we might use multiple urls in the future
    this.apiUrl = AppConfig.apiUrl;
  }

  static get = (): RestService => {
    if (!RestService.instance) {
      RestService.instance = new RestService();
    }
    return RestService.instance;
  };

  /**
   * Create fully qualified URL from supplied path
   * @param path
   */
  makeUrl(path?: string): string {
    // Append path to url
    let url = this.apiUrl;
    if (path) {
      url += path;
    }

    return url;
  }

  /**
   * Make HTTP query params string from primitive object
   * @param queryParams - object containing query params to be appended to the url
   * @returns - string containing the query params
   */
  static makeQueryParams(queryParams: QueryParams | undefined) {
    if (!queryParams) {
      return '';
    }

    const httpParams = new URLSearchParams();

    // Loop through each key-value pair in the query params object
    for (const [key, value] of Object.entries(queryParams)) {
      // If the value is an array, append each array element as a separate parameter
      if (Array.isArray(value)) {
        for (const val of value) {
          httpParams.append(key, val.toString());
        }
      }
      // Otherwise, append the value as a single parameter
      else {
        httpParams.append(key, value.toString());
      }
    }

    return `?${httpParams.toString()}`;
  }

  getResponse<ResponseType>(
    res: FractalResponse<ResponseType>,
    isArray: boolean,
    mapping: ApiMapping | undefined
  ): RequestResponse<ResponseType> {
    let data: ResponseType;
    // If no mapping is provided, return the raw response
    if (!mapping) {
      data = isArray ? res.data : res.data[0];
    }
    // Otherwise, deserialize the response using the provided mapping
    else {
      data = isArray
        ? (deserializeArray(res.data as BackendObj[], mapping) as ResponseType)
        : deserializeSingle(res.data[0], mapping);
    }

    return {
      data,
      pagination: res.pagination
    };
  }

  getParams(params: any, mapping: ApiMapping | undefined) {
    if (!mapping) {
      return params;
    }

    return deserializeSingle(params, mapping);
  }

  /**
   * Perform an HTTP request
   * @param params.method GET, POST, PUT, or DELETE
   * @param params.path Path to append to URL
   * @param params.fullUrl Fully qualified URL (overrides path)
   * @param params.body Body of the request
   * @param params.queryParams Query parameters
   * @param params.headers Query headers
   * @param params.responseMapping Response mapping for deserialization
   */
  async request<ResponseType>({
    method,
    path,
    fullUrl,
    body,
    queryParams,
    headers,
    isArray = false,
    returnRaw = true,
    paramsMapping,
    responseMapping
  }: RequestParams): Promise<any> {
    let url: string;
    if (typeof fullUrl !== 'undefined') {
      url = fullUrl;
    } else {
      url = this.makeUrl(path);
    }

    const urlWithParams = `${url}${RestService.makeQueryParams(queryParams)}`;

    switch (method) {
      case RequestMethod.GET:
        try {
          const res = await axios.get(urlWithParams, {
            method: RequestMethod.GET,
            headers: {
              // 'Max-Content-Length': 'Infinity',
              'Content-Type': 'application/json',
              ...headers
            }
          });
          const fractalRes: FractalResponse<ResponseType> = res.data;
          if (fractalRes.error) {
            throw new Error(
              `Error during GET request for url: ${urlWithParams}, Error: ${fractalRes.error}`
            );
          }
          //@ts-ignore
          if (returnRaw) return fractalRes;

          return this.getResponse(fractalRes, isArray, responseMapping);
        } catch (e: any) {
          throw new Error(`Error during GET request for url: ${urlWithParams}`);
        }

      case RequestMethod.POST:
        try {
          const res = await axios.post(
            urlWithParams,
            this.getParams(body, paramsMapping),
            {
              headers: {
                'Access-Control-Allow-Credentials': true,
                'Max-Content-Length': 'Infinity',
                'Content-Type': 'application/json',
                ...headers
              },
              withCredentials: true,
              validateStatus: function (status: number) {
                return status >= 200 && status <= 400;
              }
            }
          );
          const rbtRes: FractalResponse<ResponseType> = res.data;
          if (rbtRes.error) {
            throw new Error(rbtRes.error);
          }
          return this.getResponse(rbtRes, isArray, responseMapping);
        } catch (e: any) {
          throw new Error(e);
        }

      case RequestMethod.PUT:
        try {
          const res = await axios.put(
            urlWithParams,
            this.getParams(body, paramsMapping),
            {
              headers: {
                'Access-Control-Allow-Credentials': true,
                'Max-Content-Length': 'Infinity',
                'Content-Type': 'application/json',
                ...headers
              },
              withCredentials: true,
              validateStatus: function (status: number) {
                return status >= 200 && status <= 400;
              }
            }
          );
          const rbtRes: FractalResponse<ResponseType> = res.data;
          if (rbtRes.error) {
            throw new Error(rbtRes.error);
          }
          return this.getResponse(rbtRes, isArray, responseMapping);
        } catch (e: any) {
          throw new Error(e);
        }

      case RequestMethod.DELETE:
        try {
          const res = await axios.delete(urlWithParams, {
            headers: {
              // 'Access-Control-Allow-Credentials': true,
              'Max-Content-Length': 'Infinity',
              'Content-Type': 'application/json',
              ...headers
            },
            data: this.getParams(body, paramsMapping),
            withCredentials: true,
            validateStatus: function (status: number) {
              return status >= 200 && status <= 400;
            }
          });
          const rbtRes: FractalResponse<ResponseType> = res.data;
          if (rbtRes.error) {
            throw new Error(rbtRes.error);
          }
          return this.getResponse(rbtRes, isArray, responseMapping);
        } catch (e: any) {
          throw new Error(e);
        }
      default:
        throw new Error(`Invalid request type: ${method}`);
    }
  }

  /**
   * Perform an HTTP private request
   * @param params.method GET, POST, PUT, or DELETE
   * @param params.path Path to append to URL
   * @param params.requestParams Request parameters
   * @param params.responseMapping Response mapping for deserialization
   * @param params.paramsMapping Request params mapping for serialization
   */
  async privateRequest<ResponseType>({
    method,
    endpoint,
    paramsMapping,
    responseMapping,
    accountSecrets,
    isArray = false,
    queryParams,
    requestHeaders = {}
  }: {
    method: RequestMethod;
    endpoint: string;
    paramsMapping?: ApiMapping | undefined;
    responseMapping?: ApiMapping | undefined;
    accountSecrets: FrontendSecrets;
    isArray?: boolean;
    queryParams?: QueryParams;
    requestHeaders?: Record<string, string>;
  }): Promise<any> {
    const headers = {

      Authorization: `${accountSecrets.jwt}`,

      ...requestHeaders
    };

    return await this.request<any>({
      method,
      path: endpoint,
      headers,
      responseMapping,
      paramsMapping,
      isArray,
      queryParams
    });
  }
}
