export const countryList = [
  { id: 1, name: 'Afghanistan' },
  { id: 2, name: 'Aland Islands' },
  { id: 3, name: 'Albania' },
  { id: 4, name: 'Algeria' },
  { id: 5, name: 'American Samoa' },
  { id: 6, name: 'Andorra' },
  { id: 7, name: 'Angola' },
  { id: 8, name: 'Anguilla' },
  { id: 9, name: 'Antarctica' },
  { id: 10, name: 'Antigua And Barbuda' },
  { id: 11, name: 'Argentina' },
  { id: 12, name: 'Armenia' },
  { id: 13, name: 'Aruba' },
  { id: 14, name: 'Australia' },
  { id: 15, name: 'Austria' },
  { id: 16, name: 'Azerbaijan' },
  { id: 18, name: 'Bahrain' },
  { id: 19, name: 'Bangladesh' },
  { id: 20, name: 'Barbados' },
  { id: 21, name: 'Belarus' },
  { id: 22, name: 'Belgium' },
  { id: 23, name: 'Belize' },
  { id: 24, name: 'Benin' },
  { id: 25, name: 'Bermuda' },
  { id: 26, name: 'Bhutan' },
  { id: 27, name: 'Bolivia' },
  { id: 155, name: 'Bonaire, Sint Eustatius and Saba' },
  { id: 28, name: 'Bosnia and Herzegovina' },
  { id: 29, name: 'Botswana' },
  { id: 30, name: 'Bouvet Island' },
  { id: 31, name: 'Brazil' },
  { id: 32, name: 'British Indian Ocean Territory' },
  { id: 33, name: 'Brunei' },
  { id: 34, name: 'Bulgaria' },
  { id: 35, name: 'Burkina Faso' },
  { id: 36, name: 'Burundi' },
  { id: 37, name: 'Cambodia' },
  { id: 38, name: 'Cameroon' },
  { id: 39, name: 'Canada' },
  { id: 40, name: 'Cape Verde' },
  { id: 41, name: 'Cayman Islands' },
  { id: 42, name: 'Central African Republic' },
  { id: 43, name: 'Chad' },
  { id: 44, name: 'Chile' },
  { id: 45, name: 'China' },
  { id: 46, name: 'Christmas Island' },
  { id: 47, name: 'Cocos (Keeling) Islands' },
  { id: 48, name: 'Colombia' },
  { id: 49, name: 'Comoros' },
  { id: 50, name: 'Congo' },
  { id: 52, name: 'Cook Islands' },
  { id: 53, name: 'Costa Rica' },
  { id: 54, name: "Cote D'Ivoire (Ivory Coast)" },
  { id: 55, name: 'Croatia' },
  { id: 56, name: 'Cuba' },
  { id: 249, name: 'Curaçao' },
  { id: 57, name: 'Cyprus' },
  { id: 58, name: 'Czech Republic' },
  { id: 51, name: 'Democratic Republic of the Congo' },
  { id: 59, name: 'Denmark' },
  { id: 60, name: 'Djibouti' },
  { id: 61, name: 'Dominica' },
  { id: 62, name: 'Dominican Republic' },
  { id: 63, name: 'East Timor' },
  { id: 64, name: 'Ecuador' },
  { id: 65, name: 'Egypt' },
  { id: 66, name: 'El Salvador' },
  { id: 67, name: 'Equatorial Guinea' },
  { id: 68, name: 'Eritrea' },
  { id: 69, name: 'Estonia' },
  { id: 70, name: 'Ethiopia' },
  { id: 71, name: 'Falkland Islands' },
  { id: 72, name: 'Faroe Islands' },
  { id: 73, name: 'Fiji Islands' },
  { id: 74, name: 'Finland' },
  { id: 75, name: 'France' },
  { id: 76, name: 'French Guiana' },
  { id: 77, name: 'French Polynesia' },
  { id: 78, name: 'French Southern Territories' },
  { id: 79, name: 'Gabon' },
  { id: 80, name: 'Gambia The' },
  { id: 81, name: 'Georgia' },
  { id: 82, name: 'Germany' },
  { id: 83, name: 'Ghana' },
  { id: 84, name: 'Gibraltar' },
  { id: 85, name: 'Greece' },
  { id: 86, name: 'Greenland' },
  { id: 87, name: 'Grenada' },
  { id: 88, name: 'Guadeloupe' },
  { id: 89, name: 'Guam' },
  { id: 90, name: 'Guatemala' },
  { id: 91, name: 'Guernsey and Alderney' },
  { id: 92, name: 'Guinea' },
  { id: 93, name: 'Guinea-Bissau' },
  { id: 94, name: 'Guyana' },
  { id: 95, name: 'Haiti' },
  { id: 96, name: 'Heard Island and McDonald Islands' },
  { id: 97, name: 'Honduras' },
  { id: 98, name: 'Hong Kong S.A.R.' },
  { id: 99, name: 'Hungary' },
  { id: 100, name: 'Iceland' },
  { id: 101, name: 'India' },
  { id: 102, name: 'Indonesia' },
  { id: 103, name: 'Iran' },
  { id: 104, name: 'Iraq' },
  { id: 105, name: 'Ireland' },
  { id: 106, name: 'Israel' },
  { id: 107, name: 'Italy' },
  { id: 108, name: 'Jamaica' },
  { id: 109, name: 'Japan' },
  { id: 110, name: 'Jersey' },
  { id: 111, name: 'Jordan' },
  { id: 112, name: 'Kazakhstan' },
  { id: 113, name: 'Kenya' },
  { id: 114, name: 'Kiribati' },
  { id: 248, name: 'Kosovo' },
  { id: 117, name: 'Kuwait' },
  { id: 118, name: 'Kyrgyzstan' },
  { id: 119, name: 'Laos' },
  { id: 120, name: 'Latvia' },
  { id: 121, name: 'Lebanon' },
  { id: 122, name: 'Lesotho' },
  { id: 123, name: 'Liberia' },
  { id: 124, name: 'Libya' },
  { id: 125, name: 'Liechtenstein' },
  { id: 126, name: 'Lithuania' },
  { id: 127, name: 'Luxembourg' },
  { id: 128, name: 'Macau S.A.R.' },
  { id: 130, name: 'Madagascar' },
  { id: 131, name: 'Malawi' },
  { id: 132, name: 'Malaysia' },
  { id: 133, name: 'Maldives' },
  { id: 134, name: 'Mali' },
  { id: 135, name: 'Malta' },
  { id: 136, name: 'Man (Isle of)' },
  { id: 137, name: 'Marshall Islands' },
  { id: 138, name: 'Martinique' },
  { id: 139, name: 'Mauritania' },
  { id: 140, name: 'Mauritius' },
  { id: 141, name: 'Mayotte' },
  { id: 142, name: 'Mexico' },
  { id: 143, name: 'Micronesia' },
  { id: 144, name: 'Moldova' },
  { id: 145, name: 'Monaco' },
  { id: 146, name: 'Mongolia' },
  { id: 147, name: 'Montenegro' },
  { id: 148, name: 'Montserrat' },
  { id: 149, name: 'Morocco' },
  { id: 150, name: 'Mozambique' },
  { id: 151, name: 'Myanmar' },
  { id: 152, name: 'Namibia' },
  { id: 153, name: 'Nauru' },
  { id: 154, name: 'Nepal' },
  { id: 156, name: 'Netherlands' },
  { id: 157, name: 'New Caledonia' },
  { id: 158, name: 'New Zealand' },
  { id: 159, name: 'Nicaragua' },
  { id: 160, name: 'Niger' },
  { id: 161, name: 'Nigeria' },
  { id: 162, name: 'Niue' },
  { id: 163, name: 'Norfolk Island' },
  { id: 115, name: 'North Korea' },
  { id: 129, name: 'North Macedonia' },
  { id: 164, name: 'Northern Mariana Islands' },
  { id: 165, name: 'Norway' },
  { id: 166, name: 'Oman' },
  { id: 167, name: 'Pakistan' },
  { id: 168, name: 'Palau' },
  { id: 169, name: 'Palestinian Territory Occupied' },
  { id: 170, name: 'Panama' },
  { id: 171, name: 'Papua new Guinea' },
  { id: 172, name: 'Paraguay' },
  { id: 173, name: 'Peru' },
  { id: 174, name: 'Philippines' },
  { id: 175, name: 'Pitcairn Island' },
  { id: 176, name: 'Poland' },
  { id: 177, name: 'Portugal' },
  { id: 178, name: 'Puerto Rico' },
  { id: 179, name: 'Qatar' },
  { id: 180, name: 'Reunion' },
  { id: 181, name: 'Romania' },
  { id: 182, name: 'Russia' },
  { id: 183, name: 'Rwanda' },
  { id: 184, name: 'Saint Helena' },
  { id: 185, name: 'Saint Kitts And Nevis' },
  { id: 186, name: 'Saint Lucia' },
  { id: 187, name: 'Saint Pierre and Miquelon' },
  { id: 188, name: 'Saint Vincent And The Grenadines' },
  { id: 189, name: 'Saint-Barthelemy' },
  { id: 190, name: 'Saint-Martin (French part)' },
  { id: 191, name: 'Samoa' },
  { id: 192, name: 'San Marino' },
  { id: 193, name: 'Sao Tome and Principe' },
  { id: 194, name: 'Saudi Arabia' },
  { id: 195, name: 'Senegal' },
  { id: 196, name: 'Serbia' },
  { id: 197, name: 'Seychelles' },
  { id: 198, name: 'Sierra Leone' },
  { id: 199, name: 'Singapore' },
  { id: 250, name: 'Sint Maarten (Dutch part)' },
  { id: 200, name: 'Slovakia' },
  { id: 201, name: 'Slovenia' },
  { id: 202, name: 'Solomon Islands' },
  { id: 203, name: 'Somalia' },
  { id: 204, name: 'South Africa' },
  { id: 205, name: 'South Georgia' },
  { id: 116, name: 'South Korea' },
  { id: 206, name: 'South Sudan' },
  { id: 207, name: 'Spain' },
  { id: 208, name: 'Sri Lanka' },
  { id: 209, name: 'Sudan' },
  { id: 210, name: 'Suriname' },
  { id: 211, name: 'Svalbard And Jan Mayen Islands' },
  { id: 212, name: 'Swaziland' },
  { id: 213, name: 'Sweden' },
  { id: 214, name: 'Switzerland' },
  { id: 215, name: 'Syria' },
  { id: 216, name: 'Taiwan' },
  { id: 217, name: 'Tajikistan' },
  { id: 218, name: 'Tanzania' },
  { id: 219, name: 'Thailand' },
  { id: 17, name: 'The Bahamas' },
  { id: 220, name: 'Togo' },
  { id: 221, name: 'Tokelau' },
  { id: 222, name: 'Tonga' },
  { id: 223, name: 'Trinidad And Tobago' },
  { id: 224, name: 'Tunisia' },
  { id: 225, name: 'Turkey' },
  { id: 226, name: 'Turkmenistan' },
  { id: 227, name: 'Turks And Caicos Islands' },
  { id: 228, name: 'Tuvalu' },
  { id: 229, name: 'Uganda' },
  { id: 230, name: 'Ukraine' },
  { id: 231, name: 'United Arab Emirates' },
  { id: 232, name: 'United Kingdom' },
  { id: 233, name: 'United States' },
  { id: 234, name: 'United States Minor Outlying Islands' },
  { id: 235, name: 'Uruguay' },
  { id: 236, name: 'Uzbekistan' },
  { id: 237, name: 'Vanuatu' },
  { id: 238, name: 'Vatican City State (Holy See)' },
  { id: 239, name: 'Venezuela' },
  { id: 240, name: 'Vietnam' },
  { id: 241, name: 'Virgin Islands (British)' },
  { id: 242, name: 'Virgin Islands (US)' },
  { id: 243, name: 'Wallis And Futuna Islands' },
  { id: 244, name: 'Western Sahara' },
  { id: 245, name: 'Yemen' },
  { id: 246, name: 'Zambia' },
  { id: 247, name: 'Zimbabwe' }
];
