import React, { useEffect, useState } from 'react';
import Loader from 'components/loader/loader';
import { initiateAnalysis } from '../three-steps/accountApi';
import { useParams, useNavigate } from 'react-router-dom';
import './styles.scss';
import { getUserDetails } from '../profile-screen/userApi';
import {
  updateAccounts,
  setUserdetails,
  selectUserDetails,
  setDefaultAccount
} from '../login/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Header/component';

const AnalysisSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(selectUserDetails);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const { accountId } = useParams();
  const account_id = accountId.split('=')[1];

  useEffect(() => {
    if (data?.user === undefined) fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const data = await getUserDetails();
      dispatch(setUserdetails(data));
      dispatch(updateAccounts(data?.accounts || []));
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const generateReport = async () => {
    setLoading(true);
    setErrorMsg('');
    try {
      const res = await initiateAnalysis(account_id);
      if (res.success) {

        const data = await getUserDetails();

        dispatch(setUserdetails(data));
        dispatch(updateAccounts(data?.accounts || []));
        dispatch(setDefaultAccount(account_id));
        setLoading(false);
        navigate(`/analysis/${account_id}`, { state: account_id });
      } else {

        setErrorMsg(res?.response?.data?.message);
        setLoading(false);
      }
    } catch (error) {
      setErrorMsg(error.res?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Header activeLink="analysis" loading={loading} />
      <div className="main_page">
        <div className="left_container">
          <div className="main--container">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-12 col-lg-12 p-0">
                  <div className="table-container">
                    <div className="table-sub-container">
                      <div
                        className="fa-filter-block"
                        style={{ borderBottom: 'none' }}
                      ></div>
                      <div className="initiateModalContainer">
                        <div className="initiateModalBody">
                          <h1>
                            Data analysis activated for Account #{account_id}
                          </h1>
                          {loading && (
                            <div className="initiateModalLoader">
                              <Loader />
                            </div>
                          )}
                          <p>
                            Your trading history is in the lab! Hang tight,
                            we’ll ping you as soon as it's ready. Once done,
                            you'll unlock the full power of Fractal Alpha.
                          </p>
                          <p>Please do not refresh the page or click the “Back” or “Close” button of your browser</p>

                          <br />
                          <button
                            className="btn btn-primary btn-sm btn-xll"
                            onClick={generateReport}
                            disabled={loading}
                          >
                            Initiate Analysis
                          </button>
                          <p className="errormsg">{errorMsg}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalysisSuccess;
