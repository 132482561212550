import React from 'react';
import './styles.scss';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Header/component';
const AnalysisFailed = () => {
  const navigate = useNavigate();
  const { accountId } = useParams();
  const id = accountId.split('=')[1];
  const onBack = () => {
    navigate(`/new-account?account_id=${id}`);
  };

  return (
    <>
      <Header activeLink="analysis" />
      <div className="main_page">
        <div className="left_container">
          <div className="main--container">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-12 col-lg-12 p-0">
                  <div className="table-container">
                    <div className="table-sub-container">
                      <div className="fa-filter-block"></div>
                      <div className="initiateModalContainer">
                        <div className="initiateModalBody">
                          <h1>
                            Data analysis can not be activated for Account #{id}
                          </h1>
                          <span className="dataerror">
                            Insufficient Trading Data Available
                          </span>
                          <p>
                            It seems there are fewer than 100 trades recorded on
                            this MetaTrader Account. Fractal Alpha requires a
                            minimum of 100 trades to accurately analyze your
                            trading style and develop a comprehensive trading
                            plan.
                            <br />
                            We encourage you to continue trading, and once you
                            have surpassed the threshold of 100 trades, please
                            initiate the analysis on this account again.
                          </p>
                          <br />
                          <button
                            className="btn btn-primary btn-sm btn-xll"
                            onClick={onBack}
                          >
                            Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalysisFailed;
