/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BrandLogo from '../../assets/images/logo-white.png';
import ProfileIocn from '../../assets/Img/profile-icon.png';
import { useLocation } from 'react-router-dom';
import Billing from '../../assets/Img/billing-icon.png';
import HelpDesk from '../../assets/Img/helpdesk.png';
import User from '../../assets/svgIcons/User';
import { useSelector } from 'react-redux';
import { selectAccounts } from 'features/login/loginSlice';
import { NavLink } from 'react-router-dom';
import './styles.scss';
import { selectUserName, selectEmail } from '../login/loginSlice';
import { DownArrow } from '../../assets/svgIcons';
import NewUserAccount from '../../features/new-user-account/component';

const Helper = require('../../utils/helper');

const HomeComponent = (props) => {
  const [InitiateModal, setInitiateModal] = useState(true);
  const InitiateModaltoggle = () => setInitiateModal(!InitiateModaltoggle);
  const [isNewUser, setNewUser] = useState(false);
  const email = useSelector(selectEmail);
  const userName = useSelector(selectUserName);
  const name = userName
    ? userName.charAt(0).toUpperCase() + userName.slice(1)
    : '';

  const navigate = useNavigate();
  const allAccounts = useSelector(selectAccounts);
  const [activeLink, setActiveLink] = useState('accounts');
  // const handleLinkClick = (link) => {
  //   let val = Helper.checkIfNewUser(allAccounts);
  //   setNewUser(val);
  //   if (!val && link !== 'accounts') {
  //     navigate(`/${link}`);
  //   }
  //   setActiveLink(link);
  //   setInitiateModal(true);
  // };
  const location = useLocation();
  const handleLinkClick = (link) => {
    let val = Helper.checkIfNewUser(allAccounts);
    setNewUser(val);
    const isNewAccountPage = location.pathname === '/new-account';

    if (!val && link !== 'accounts') {
      navigate(`/${link}`);
    } else if (link !== 'new-account' && !isNewAccountPage) {
      setActiveLink(link);
      if (link !== activeLink) {
        setInitiateModal(true);
      }
    }
  };

  return (
    <>
      {isNewUser && activeLink !== 'accounts' && activeLink !== '' && (
        <NewUserAccount
          InitiateModal={InitiateModal}
          InitiateModaltoggle={InitiateModaltoggle}
        />
      )}
      <div className="header-main">
        <nav className="navbar navbar-expand-lg dark-bg">
          <div className="container-fluid">
            <a
              className="navbar-brand p-0 custom-brand"
              onClick={() => {
                navigate('/accounts');
              }}
            >
              <img alt="BrandLogo" src={BrandLogo} className="brand-logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto ">
                <li>
                  <NavLink
                    end={true}
                    to="/accounts"
                    className={
                      !props.activeLink && activeLink === 'accounts'
                        ? 'nav-link green'
                        : 'nav-link'
                    }
                    onClick={() => handleLinkClick('accounts')}
                  >
                    Accounts
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="#"
                    className={
                      props.activeLink === 'analysis' ||
                      activeLink === 'analysis'
                        ? 'nav-link green'
                        : 'nav-link'
                    }
                    onClick={() => handleLinkClick('analysis')}
                  >
                    Analysis
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="#"
                    className={
                      props.activeLink === 'trades' || activeLink === 'trades'
                        ? 'nav-link green'
                        : 'nav-link'
                    }
                    onClick={() => handleLinkClick('trades')}
                  >
                    Trades
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="#"
                    className={
                      props.activeLink === 'journal' || activeLink === 'journal'
                        ? 'nav-link green'
                        : 'nav-link'
                    }
                    onClick={() => handleLinkClick('journal')}
                  >
                    Journal
                  </NavLink>
                </li>
              </ul>
              <div className="navbar-nav gap-3 headerright">
                <div className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <User className="me-2" />
                    {name}
                    <DownArrow className="toggle-down-arrow" />
                  </a>
                  <div className="dropdown-menu">
                    <div className="profile__text">
                      <div className="profileText">
                        <span>{userName?.charAt(0)?.toUpperCase()}</span>
                      </div>
                      <div className="profile--text">
                        <h6>{name}</h6>
                        <p>{email}</p>
                      </div>
                    </div>
                    <ul className="profile-billing">
                      <li
                        onClick={() => {
                          navigate('/profile');
                        }}
                      >
                        <a className="dropdown-item">
                          <div className="profile--div gap-3">
                            <div className="profile--icons">
                              <img alt="profile icon" src={ProfileIocn} />
                            </div>
                            <div className="profile--text">
                              <h6>My Profile</h6>
                              <p>Account settings and more</p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          navigate('/billing');
                        }}
                      >
                        <a className="dropdown-item">
                          <div className="profile--div gap-3">
                            <div className="profile--icons">
                              <img alt="billing" src={Billing} />
                            </div>
                            <div className="profile--text">
                              <h6>Billing</h6>
                              <p>Billing & statements</p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          navigate('/helpdesk');
                        }}
                      >
                        <a className="dropdown-item">
                          <div className="profile--div gap-3">
                            <div className="profile--icons">
                              <img alt="helpDesk" src={HelpDesk} />
                            </div>
                            <div className="profile--text">
                              <h6>Help Desk</h6>
                              <p>Support Tickets & FAQ</p>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                    <div className="p-3">
                      <button
                        className="btn btn-primary btn-sm w-100"
                        onClick={(e) => {
                          e.preventDefault();
                          localStorage.clear();
                          sessionStorage.removeItem('persist:root');
                          // window.location.href = '/';
                          // Clear browser history to prevent navigation back to protected routes
                          window.location.replace('/');
                        }}
                      >
                        Sign out
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};
export default HomeComponent;
