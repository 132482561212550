import React, { useState } from 'react';
import { useField } from 'formik';
import Select from 'react-select';
import moment from 'moment-timezone';

const TimeZoneSelect = ({ ...props }) => {
  const [field, , helpers] = useField(props.name);
  const [selectedOption, setSelectedOption] = useState(null);

  const options = moment.tz.names().map((tz) => ({
    value: tz,
    label: `(GMT${moment.tz(tz).format('Z')}) ${tz}`
  }));

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    helpers.setValue(selectedOption ? selectedOption.value : '');
  };

  return (
    <>
      <Select
        {...field}
        {...props}
        value={selectedOption}
        onChange={handleChange}
        options={options}
      />
    </>
  );
};

export default TimeZoneSelect;
