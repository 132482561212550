import { configureStore } from '@reduxjs/toolkit';
import loginReducer from '../features/login/loginSlice';
import chatbotReducer from '../components/chatbot/ChatSlice';
import tradingPlanReducer from '../components/chatbot/tradingPlanSlice';
import tradesJournalReducer from '../features/fa-journal/journalSlice';
import HomeReducer from '../features/home-screen/homeSlice';

import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';

const store = configureStore({
  reducer: {
    login: loginReducer,
    chatbot: chatbotReducer,
    tradingPlan: tradingPlanReducer,
    tradesJournal: tradesJournalReducer,
    home: HomeReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export { store };
