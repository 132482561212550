// const fetchHistoricalData = async (symbol, tradeTime, closingTime) => {
//   const apiKey = '4K3RUPRGWP0JYSJO';
//   const today = new Date();
//   const oneMonthAgo = new Date(
//     today.getFullYear(),
//     today.getMonth() - 1,
//     today.getDate()
//   );

//   const formattedDate = (date) => {
//     const year = date.getFullYear();
//     let month = date.getMonth() + 1;
//     month = month < 10 ? `0${month}` : month;
//     let day = date.getDate();
//     day = day < 10 ? `0${day}` : day;
//     return `${year}-${month}-${day}`;
//   };

//   const startDate = formattedDate(oneMonthAgo);
//   const endDate = formattedDate(today);
//   const apiUrl = `https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=${symbol}&interval=5min&outputsize=compact&apikey=${apiKey}&datatype=json`;

//   try {
//     const response = await fetch(apiUrl);
//     if (!response.ok) {
//       throw new Error('Failed to fetch data');
//     }
//     const data = await response.json();

//     return data;
//   } catch (error) {
//     console.error('Error fetching historical data:', error);
//     throw error;
//   }
// };

// export default fetchHistoricalData;
import axios from 'axios';
import AppConfig from '../../../config';
export const getHistoricalData = async (
  symbol,
  start_date,
  end_date,
  trade_id
) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const data = {
      symbol: symbol,
      start_date: start_date,
      end_date: end_date,
      trade_id
    };
    const response = await axios.post(
      AppConfig.apiUrl + '/candlestick_graph',
      data,
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'An error occurred';
    console.error('Error during sign in:', error);
    throw errorMessage;
  }
};

export const fetchTradeInsights = async (trade_id) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.get(
      AppConfig.apiUrl + `/get_trade_insights?trade_id=${trade_id}`,
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'An error occurred';
    console.error('Error during sign in:', error);
    throw errorMessage;
  }
};
