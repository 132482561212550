import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import AppConfig from '../../config';

// Define the type for the API response data
type TradingPlanData = {
  message: string;
  success: boolean;
  new_trading_plan: object;
  trading_plan: object;
};

export const getTradingPlan = createAsyncThunk<
  TradingPlanData,
  string, // Argument type (account ID)
  { rejectValue: string } // Type for the rejected action payload
>('tradingplan/getTradingPlan', async (account, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('jwtToken');
    if (!token) {
      return rejectWithValue('No token found');
    }

    const response = await axios.get(
      AppConfig.apiUrl + `/get_trading_plan?account_id=${account}`,
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    let errorMessage = 'An error occurred';
    if (error instanceof AxiosError && error.response) {
      errorMessage = error.response.data?.message || errorMessage;
    }
    return rejectWithValue(errorMessage);
  }
});

// Initial state type
type TradingPlanState = {
  tradingPlan: {}; // Adjust based on actual data structure
  newtradingPlan: {}; // Adjust based on actual data structure
  loading: boolean;
  error: string | null;
};

// Initial state
const initialState: TradingPlanState = {
  tradingPlan: {},
  newtradingPlan: {},
  loading: false,
  error: null
};

// Create slice
const tradingPlanSlice = createSlice({
  name: 'tradingplan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTradingPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTradingPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.newtradingPlan = action.payload.new_trading_plan;
        state.tradingPlan = action.payload.trading_plan;
      })
      .addCase(getTradingPlan.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload === 'string') {
          state.error = action.payload;
        } else if (action.error.message) {
          state.error = action.error.message;
        } else {
          state.error = 'An unknown error occurred';
        }
      });
  }
});

export default tradingPlanSlice.reducer;
