import axios from 'axios';
import AppConfig from '../../config';

export const getPaymentHistoy = async () => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.get(AppConfig.apiUrl + '/payment_records', {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'An error occurred';
    console.error('Error during sign in:', error);
    throw errorMessage;
  }
};

export const getInvoiceFile = async (invoice_id) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.get(
      AppConfig.apiUrl + '/download_invoice-receipt',
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          invoice_id: invoice_id
        }
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'An error occurred';
    console.error('Error during sign in:', error);
    throw errorMessage;
  }
};

export const cancelSubscription = async (
  subscription_id,
  subscription_status
) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      AppConfig.apiUrl + `/update_subscription`,
      {
        subscription_id: subscription_id,
        status: subscription_status
      },
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'An error occurred';
    console.error('Error during sign in:', error);
    throw errorMessage;
  }
};
