/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Header from '../Header/component';
import CrossIcon from '../../assets/images/cross-icon.svg';
import './styles.scss';
import { Emotion, SadAngry, Sadface, Smile, Smile2 } from 'assets/svgIcons';
import { useSelector } from 'app/store';
import SubHeader from '../../features/sub-header/component';
import TradeTable from 'components/trades/tradeTable';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { TradeSide } from 'models/enums/tradeSide';
import { getFormattedDateTime } from 'utils/date';
import { forceUnaryOperator, formatPrice } from 'utils/numbers';
import { useParams } from 'react-router-dom';
import ChatbotMobileTradePlan from 'components/chatbot/chatbotMobileTradePlan';
import {
  setTradesData,
  setUserdetails,
  updateAccounts,
  setDefaultAccount,
  setTradesTotal
} from 'features/login/loginSlice';
import { addTradeInsight } from './homeApi';
import { selectAccounts } from '../login/loginSlice';
import { getUserDetails } from 'features/profile-screen/userApi';
import { useDispatch } from 'react-redux';
import Loader from 'components/loader/loader';
import ChatbotWebTradePlan from '../../components/chatbot/ChatbotWebTradePlan';
import { getTradingPlan } from 'components/chatbot/tradingPlanSlice';
import { getCalculations } from './homeApi';

const HomeComponent = (props) => {
  const [loader, setLoader] = useState(false);
  const [toggleValue, setToggleValue] = useState('No');
  const [reflections, setReflections] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const allAccounts = useSelector(selectAccounts);
  const accounts = allAccounts?.filter(
    (account) => account.status === 'active'
  );
  const dispatch = useDispatch();
  const calculation = useSelector((state) => state.home.calculation);
  const allTradesLoader = useSelector((state) => state.home.loader);

  let { accountId } = useParams();
  const [day, setDay] = useState('1095');
  const [selectedEmojis, setSelectedEmojis] = useState('');
  const [tradeData, setTradeData] = useState(null);
  const [overallProfit, setOverallProfit] = useState(0);
  const [overallLoss, setOverallLoss] = useState(0);
  const [insightModal, setInsightModal] = useState({ show: false });
  const [allTrades, setAllTrades] = useState(calculation?.all_trades);
  const [profit_metrics, setpProfits] = useState(calculation?.profit_metrics);
  const [loss_metrics, setLosses] = useState(calculation?.loss_metrics);
  const [headings, setHeadings] = useState(calculation?.headings);

  const [totaltradeCount, setTotaltradeCount] = useState(0);
  const [loader2, setLoader2] = useState(false);

  const firstAccountId = accounts.length > 0 ? accounts[0].account_id : null;
  const [wordCount, setWordCount] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState(
    accountId !== undefined
      ? accountId
      : accounts.length > 0
      ? accounts[0].account_id
      : ''
  );
  const [selectedLogin, setSelectedLogin] = useState('');
  const [isYtd, setIsYtd] = useState(false);

  useEffect(() => {
    if (selectedAccount) {
      dispatch(getTradingPlan(selectedAccount));
    }
  }, [selectedAccount]);

  useEffect(() => {
    const selected_account = accounts.find(
      (account) => account.account_id === selectedAccount
    );
    if (selected_account) {
      setSelectedLogin(selected_account.login);
    }
  }, [accountId, accounts, selectedAccount]);

  useEffect(() => {
    if (accountId !== undefined) {
      setSelectedAccount(accountId);
    } else if (firstAccountId) {
      setSelectedAccount(firstAccountId);
    }
  }, [accountId, firstAccountId]);

  useEffect(() => {
    if (accounts.length === 0) {
      fetchUser();
    }
  }, []);

  const fetchUser = async () => {
    try {
      const data = await getUserDetails();
      dispatch(setUserdetails(data));
      dispatch(updateAccounts(data?.accounts || []));
    } catch (error) {}
  };

  const showInsight = (trade) => {
    if (Object.keys(trade?.insights_details)?.length !== 0) {
      setToggleValue(
        trade?.insights_details?.follow_trading_plan
          ? trade?.insights_details?.follow_trading_plan
          : 'No'
      );
      setReflections(
        trade?.insights_details?.reflection
          ? trade?.insights_details?.reflection
          : ''
      );
      setWordCount(trade?.insights_details?.reflection.length);
      setSelectedEmojis(
        trade?.insights_details?.emoji_reaction
          ? trade?.insights_details?.emoji_reaction
          : ''
      );
    }

    setInsightModal({ show: true, trade });
    setError('');
  };

  const onCloseInsightModal = () => {
    setWordCount(0);
    setToggleValue('No');
    setReflections('');
    setSelectedEmojis('');
    setInsightModal((prev) => ({ ...prev, show: false }));
  };

  const focusedTrade = insightModal.trade;

  useEffect(() => {
    setAllTrades(calculation?.all_trades);
    setpProfits(calculation?.profit_metrics);
    setLosses(calculation?.loss_metrics);
    setHeadings(calculation?.headings);
  }, [calculation]);

  const fetchCalData = async () => {
    try {
      dispatch(getCalculations({ selectedAccount, day }));
    } catch (error) {
      console.log('error===>>', error);
    }
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      setLoader(true);
      const response = await axios.get(
        `https://api.fractalalpha.com/get_trades_page_with_insights?account_id=${selectedAccount}`,
        {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      dispatch(setTradesTotal(response.data?.totalcount));

      const result = response.data;
      const tradeLabels = result.trades.map((trade) => trade.tradeTime);
      const tradeValues = result.trades.map((trade) => trade.currentProfit);
      dispatch(setTradesData(response?.data.trades));
      setTradeData({
        labels: tradeLabels,
        datasets: [
          {
            label: 'Current Profit',
            data: tradeValues,
            fill: true,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderWidth: 4,
            pointRadius: 0,
            tension: 0.2
          }
        ]
      });
      let totalProfit = 0;
      let totalLoss = 0;

      tradeValues.forEach((value) => {
        if (value > 0) {
          totalProfit += value;
        } else {
          totalLoss += Math.abs(value);
        }
      });

      setOverallProfit(totalProfit);
      setOverallLoss(totalLoss);
      setLoader(false);
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      console.error('Error during getting user details:', error);
      return errorMessage;
    }
  };

  useEffect(() => {
    fetchCalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, day]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, day]);

  const handleEmojiClick = (emoji) => {
    setSelectedEmojis(emoji);
  };

  const handleReflections = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= 250) {
      setWordCount(inputText.length);
      setReflections(inputText);
    } else {
      event.preventDefault();
    }
  };

  const handleToggleChange = () => {
    const newValue = toggleValue === 'No' ? 'Yes' : 'No';
    setToggleValue(newValue);
  };

  const saveTradeInsight = async () => {
    setIsLoading(true);
    setError('');
    if (reflections.trim() === '') {
      setError('Please enter reflections.');
      setIsLoading(false);
      return;
    }
    if (selectedEmojis === '') {
      setError('Please select emoji.');
      setIsLoading(false);
      return;
    }
    const data = {
      emoji_reaction: selectedEmojis,
      follow_trading_plan: toggleValue,
      reflection: reflections
    };
    await addTradeInsight(focusedTrade?._id, data);
    onCloseInsightModal();
    setIsLoading(false);
    setReflections('');
    setToggleValue('No');
    setSelectedEmojis('');
    await fetchData();
  };

  const handleSelectAccount = (account) => {
    dispatch(setDefaultAccount(account));
    setSelectedAccount(account);
  };

  const onRefresh = async (account) => {
    setLoader2(true);
    await fetchData();
    await fetchCalData();
    setLoader2(false);
  };

  return (
    <>
      <div className="main_page">
        <Header />
        <div className="left_container">
          {accounts.length === 0 ? (
            <Loader />
          ) : (
            <>
              <SubHeader
                accountId={accountId}
                accounts={accounts}
                handleSelectAccount={handleSelectAccount}
                onRefresh={onRefresh}
                loader2={loader2}
              />
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <div
                          className="consistency"
                          style={{ marginBottom: '20px', marginTop: '10px' }}
                        >
                          <div className="animated-progress progress">
                            <div
                              className="progress-bar bg-danger"
                              style={{
                                width: `${headings?.consistency}%`
                              }}
                            ></div>
                          </div>
                          <div className="consistency-value">
                            <div className="con-value">0-30%</div>
                            <div className="con-value">30-80%</div>
                            <div className="con-value">80-100%</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-8">
                        {/*Today’s Summary component*/}
                        <div className="row">
                          <div className="col-lg-3 col-md-6 ps-2 pe-2">
                            <div className="card widget-content">
                              <div className="widget-heading"> Closed P/L</div>
                              <div className="widget-subheading">
                                <span
                                  className={
                                    headings?.closed_pl < 0 ? 'red' : 'green'
                                  }
                                >
                                  {headings?.closed_pl !== null &&
                                  headings?.closed_pl !== undefined
                                    ? (headings?.closed_pl < 0 ? '-' : '') +
                                      '$' +
                                      Math.abs(headings?.closed_pl)
                                    : 'N/A'}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 ps-2 pe-2">
                            <div className="card widget-content">
                              <div className="widget-heading">Expectancy</div>
                              <div className="widget-subheading">
                                {headings?.expectancy
                                  ? '$' + headings?.expectancy
                                  : 'N/A'}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 ps-2 pe-2">
                            <div className="card widget-content">
                              <div className="widget-heading">
                                Profit Factor
                              </div>
                              <div className="widget-subheading">
                                {headings?.profit_factor
                                  ? headings?.profit_factor
                                  : 'N/A'}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 ps-2 pe-2">
                            <div className="card widget-content">
                              <div className="widget-heading"> Win Rate</div>
                              <div className="widget-subheading">
                                {headings?.win_rate
                                  ? headings?.win_rate + '%'
                                  : 'N/A'}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="summary-chart">
                          <p
                            style={{
                              fontWeight: 'bold',
                              textDecoration: 'underline',
                              fontSize: '20px'
                            }}
                          >
                            Trade's Summary
                          </p>
                          <div className="profit">
                            <div>
                              <span>Profits ${Math.floor(overallProfit)}</span>
                            </div>
                          </div>
                          <div>
                            {tradeData !== null ? (
                              <Line
                                data={{
                                  labels: tradeData.labels,
                                  datasets: tradeData.datasets.map(
                                    (dataset) => ({
                                      ...dataset,
                                      fill: true
                                    })
                                  )
                                }}
                                options={{
                                  scales: {
                                    x: {
                                      display: false
                                    }
                                  },
                                  elements: {
                                    line: {
                                      tension: 0.4
                                    }
                                  },
                                  responsive: true,
                                  maintainAspectRatio: false,
                                  width: 1000,
                                  height: 850
                                }}
                              />
                            ) : (
                              <p>Loading chart data...</p>
                            )}
                          </div>
                          <div className="loses">
                            <div>
                              <span>Loses -${Math.floor(overallLoss)}</span>
                            </div>
                          </div>

                          {/*Today’s Trades component*/}
                          <div className="trades-block">
                            <p
                              style={{
                                fontWeight: 'bold',
                                textDecoration: 'underline',
                                fontSize: '20px'
                              }}
                            >
                              Trades
                            </p>

                            <TradeTable
                              setTotaltradeCount={setTotaltradeCount}
                              accountNo={selectedAccount}
                              onRowPress={(trade) => showInsight(trade)}
                            />
                          </div>
                        </div>
                      </div>

                      {/* ALL TRADES component */}
                      <div className="col-xl-4">
                        <div className="days-component">
                          {/* ALL TRADES HEADING */}
                          <ul className=" nav nav-pills" role="tablist">
                            <li className=" col-lg-2 nav-item">
                              <button
                                className={`nav-link ${
                                  day === '30' ? 'active' : ''
                                } fill-available`}
                                onClick={() => {
                                  setDay('30');
                                  setIsYtd(false);
                                }}
                              >
                                30D
                              </button>
                            </li>
                            <li className="col-lg-2 nav-item">
                              <button
                                className={`nav-link ${
                                  day === '90' ? 'active' : ''
                                } fill-available`}
                                onClick={() => {
                                  setDay('90');
                                  setIsYtd(false);
                                }}
                              >
                                90D
                              </button>
                            </li>
                            <li className="col-lg-2 nav-item">
                              <button
                                className={`nav-link ${
                                  day === '180' ? 'active' : ''
                                } fill-available`}
                                onClick={() => {
                                  setDay('180');
                                  setIsYtd(false);
                                }}
                              >
                                6M
                              </button>
                            </li>
                            <li className="col-lg-2 nav-item">
                              <button
                                className={`nav-link ${
                                  isYtd === true ? 'active' : ''
                                } fill-available`}
                                onClick={() => {
                                  const desiredPastDate = new Date(
                                    '2023-01-01'
                                  );
                                  const currentDate = new Date();
                                  const timeDifference =
                                    currentDate - desiredPastDate;
                                  const daysDifference = Math.floor(
                                    timeDifference / (24 * 60 * 60 * 1000)
                                  );
                                  setDay(daysDifference.toString());
                                  setIsYtd(true);
                                }}
                              >
                                YTD
                              </button>
                            </li>
                            <li className="col-lg-2 nav-item">
                              <button
                                className={`nav-link ${
                                  day === '365' ? 'active' : ''
                                } fill-available`}
                                onClick={() => {
                                  setDay('365');
                                  setIsYtd(false);
                                }}
                              >
                                1Y
                              </button>
                            </li>
                            <li className="col-lg-2 nav-item">
                              <button
                                className={`nav-link ${
                                  day === '1095' ? 'active' : ''
                                } fill-available`}
                                onClick={() => {
                                  setDay('1095');
                                  setIsYtd(false);
                                }}
                              >
                                3Y
                              </button>
                            </li>
                          </ul>
                          <div className="tab-content">
                            {/* ALL TRADES CONTENT */}
                            <div
                              className="container tab-pane active"
                              style={{ minHeight: 300 }}
                            >
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '20%',
                                  left: '45%',
                                  transform: 'translate(-50%, -50)'
                                }}
                              >
                                {allTradesLoader && <Loader size={50} />}
                              </div>
                              <>
                                {allTrades && (
                                  <>
                                    <ul className="p-0">
                                      <p className="daystitle mb-2">
                                        <span>ALL TRADES</span>
                                      </p>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Gross P/L :</p>
                                        <p className="text-end">
                                          {allTrades?.gross_pl
                                            ? (allTrades.gross_pl >= 0
                                                ? ''
                                                : '-') +
                                              '$' +
                                              Math.abs(allTrades.gross_pl)
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Number of Trades :</p>
                                        <p className="text-end">
                                          {allTrades?.num_trades
                                            ? allTrades?.num_trades
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Avg. Trade Time :</p>
                                        <p className="text-end">
                                          {allTrades?.avg_trade_time &&
                                          allTrades?.avg_trade_time !==
                                            '0 days, 0:00:00'
                                            ? allTrades.avg_trade_time
                                            : 'N/A'}
                                        </p>
                                      </li>

                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Longest Trade Time :</p>
                                        <p className="text-end">
                                          {allTrades?.longest_trade_time &&
                                          allTrades?.longest_trade_time !==
                                            '0 days, 0:00:00'
                                            ? allTrades.longest_trade_time
                                            : 'N/A'}
                                        </p>
                                      </li>

                                      <li className="d-flex justify-content-between gap-4">
                                        <p>% Profitable Trades :</p>
                                        <p className="text-end">
                                          {allTrades?.percentage_profitable_trades
                                            ? allTrades?.percentage_profitable_trades
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Expectancy :</p>
                                        <p className="text-end">
                                          {allTrades?.expectancy
                                            ? (allTrades.expectancy >= 0
                                                ? '$'
                                                : '-$') +
                                              Math.abs(allTrades.expectancy)
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Trade Fees & Comm. :</p>
                                        <p className="text-end">
                                          {allTrades?.gross_pl
                                            ? (allTrades.gross_pl < 0
                                                ? '-$'
                                                : '$') +
                                              Math.abs(allTrades.gross_pl)
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Total P/L :</p>
                                        <p className="text-end">
                                          {allTrades?.total_pl
                                            ? (allTrades.total_pl < 0
                                                ? '-$'
                                                : '$') +
                                              Math.abs(allTrades.total_pl)
                                            : 'N/A'}
                                        </p>
                                      </li>
                                    </ul>
                                    {/* PROFIT TRADES CONTENT */}
                                    <ul className="p-0">
                                      <p className="daystitle success mb-2">
                                        <span>PROFIT TRADES</span>
                                      </p>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Total Profit :</p>
                                        <p className="text-end">
                                          {profit_metrics?.total_profit
                                            ? (profit_metrics.total_profit < 0
                                                ? '- $'
                                                : '$') +
                                              Math.abs(
                                                profit_metrics.total_profit
                                              )
                                            : 'N/A'}
                                        </p>
                                      </li>

                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Number of Winning Trades :</p>
                                        <p className="text-end">
                                          {profit_metrics?.num_winning_trades
                                            ? profit_metrics?.num_winning_trades
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Number of Winning Contracts :</p>
                                        <p className="text-end">
                                          {profit_metrics?.num_winning_contracts
                                            ? profit_metrics?.num_winning_contracts
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Largest Winning Trade :</p>
                                        <p className="text-end">
                                          {profit_metrics?.largest_winning_trade
                                            ? '$' +
                                              profit_metrics?.largest_winning_trade
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Avg. Winning Trade :</p>
                                        <p className="text-end">
                                          {profit_metrics?.avg_winning_trade
                                            ? '$' +
                                              profit_metrics?.avg_winning_trade
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Std. Dev. Winning Trade :</p>
                                        <p className="text-end">
                                          {profit_metrics?.std_dev_winning_trade
                                            ? '$' +
                                              profit_metrics?.std_dev_winning_trade
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p style={{ whiteSpace: 'nowrap' }}>
                                          Avg. Winning Trade Time :
                                        </p>
                                        <p className="text-end">
                                          {profit_metrics?.avg_winning_trade_time &&
                                          profit_metrics?.avg_winning_trade_time !==
                                            '0 days, 0:00:00'
                                            ? profit_metrics.avg_winning_trade_time
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p style={{ wordBreak: 'break-all' }}>
                                          Longest Winning Trade Time :
                                        </p>
                                        <p className="text-end">
                                          {profit_metrics?.longest_winning_trade_time &&
                                          profit_metrics?.longest_winning_trade_time !==
                                            '0 days, 0:00:00'
                                            ? profit_metrics.longest_winning_trade_time
                                            : 'N/A'}
                                        </p>
                                      </li>

                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Max Run-up :</p>
                                        <p className="text-end">
                                          {profit_metrics?.maxrunup
                                            ? '$' + profit_metrics?.maxrunup
                                            : 'N/A'}
                                        </p>
                                      </li>
                                    </ul>
                                    {/* LOSING TRADES CONTENT */}
                                    <ul className="p-0 m-0">
                                      <p className="daystitle danger mb-2">
                                        <span>LOSING TRADES</span>
                                      </p>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Total Loss :</p>
                                        <p className="text-end">
                                          {loss_metrics?.total_loss
                                            ? (loss_metrics.total_loss < 0
                                                ? '- $'
                                                : '$') +
                                              Math.abs(loss_metrics.total_loss)
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Number of Losing Trades :</p>
                                        <p className="text-end">
                                          {loss_metrics?.num_losing_trades
                                            ? loss_metrics?.num_losing_trades
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Number of Losing Contracts :</p>
                                        <p className="text-end">
                                          {loss_metrics?.num_losing_contracts
                                            ? loss_metrics?.num_losing_contracts
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Largest Losing Trade :</p>
                                        <p className="text-end">
                                          {loss_metrics?.largest_losing_trade
                                            ? (loss_metrics.largest_losing_trade <
                                              0
                                                ? '- $'
                                                : '$') +
                                              Math.abs(
                                                loss_metrics.largest_losing_trade
                                              )
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Avg.Losing Trade :</p>
                                        <p className="text-end">
                                          {loss_metrics?.avg_losing_trade
                                            ? '- $' +
                                              Math.abs(
                                                loss_metrics?.avg_losing_trade
                                              )
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Std. Dev. Losing Trade : </p>
                                        <p className="text-end">
                                          {loss_metrics?.std_dev_losing_trade
                                            ? '- $' +
                                              Math.abs(
                                                loss_metrics?.std_dev_losing_trade
                                              )
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Avg. Losing Trade Time :</p>
                                        <p className="text-end">
                                          {loss_metrics?.avg_losing_trade &&
                                          loss_metrics?.avg_losing_trade !==
                                            '0 days, 0:00:00'
                                            ? loss_metrics.avg_losing_trade
                                            : 'N/A'}
                                        </p>
                                      </li>

                                      <li className="d-flex justify-content-between gap-4">
                                        <p style={{ whiteSpace: 'nowrap' }}>
                                          Longest Losing Trade Time :
                                        </p>
                                        <p className="text-end">
                                          {loss_metrics?.longest_losing_trade_time &&
                                          loss_metrics?.longest_losing_trade_time !==
                                            '0 days, 0:00:00'
                                            ? loss_metrics.longest_losing_trade_time
                                            : 'N/A'}
                                        </p>
                                      </li>

                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Max Drawdown : </p>

                                        <p className="text-end">
                                          {loss_metrics?.maxdrawdown
                                            ? '- $' +
                                              Math.abs(
                                                loss_metrics?.maxdrawdown
                                              )
                                            : 'N/A'}
                                        </p>
                                      </li>
                                    </ul>
                                  </>
                                )}
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div>
            {
              <ChatbotMobileTradePlan
                from={'account'}
                selectedAccount={selectedAccount}
                selectedLogin={selectedLogin}
                // isMobile ={isMobile}
              />
            }
          </div>
        </div>

        <div className="right_container">
          <ChatbotWebTradePlan
            from={'account'}
            selectedAccount={selectedAccount}
            selectedLogin={selectedLogin}
          />
        </div>
      </div>

      {/*Modal component*/}
      <Modal
        isOpen={insightModal.show}
        toggle={onCloseInsightModal}
        className="trades-details-modal"
        size="lg"
        centered
      >
        <ModalHeader className="border-0 pb-0">
          Trade Insight
          <img
            alt="CrossIcon"
            src={CrossIcon}
            style={{ padding: '5px', float: 'right', cursor: 'pointer' }}
            onClick={onCloseInsightModal}
          />
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-8">
              <p className="mb-2">How did I feel?</p>
              <div className="check-emoje">
                <span
                  className={`emoje ${
                    selectedEmojis === 'laugh' ? 'selected' : ''
                  }`}
                  onClick={() => handleEmojiClick('laugh')}
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                >
                  <Smile
                    className={`${
                      selectedEmojis === 'laugh' && selectedEmojis.length > 0
                        ? 'emojeIcon'
                        : ''
                    }`}
                  />
                </span>
                <span
                  className={`emoje ${
                    selectedEmojis === 'smile' ? 'selected' : ''
                  }`}
                  onClick={() => handleEmojiClick('smile')}
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                >
                  <Smile2
                    className={`${
                      selectedEmojis === 'smile' && selectedEmojis.length > 0
                        ? 'emojeIcon'
                        : ''
                    }`}
                  />
                </span>
                <span
                  className={`emoje ${
                    selectedEmojis === 'speechless' ? 'selected' : ''
                  }`}
                  onClick={() => handleEmojiClick('speechless')}
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                >
                  <Emotion
                    className={`${
                      selectedEmojis === 'speechless' &&
                      selectedEmojis.length > 0
                        ? 'emojeIcon'
                        : ''
                    }`}
                  />
                </span>
                <span
                  className={`emoje ${selectedEmojis === 'sad' ? 'sad' : ''}`}
                  onClick={() => handleEmojiClick('sad')}
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                >
                  <Sadface
                    className={`${
                      selectedEmojis === 'sad' && selectedEmojis.length > 0
                        ? 'emojeIcon'
                        : ''
                    }`}
                  />
                </span>
                <span
                  className={`emoje ${selectedEmojis === 'angry' ? 'sad' : ''}`}
                  onClick={() => handleEmojiClick('angry')}
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                >
                  <SadAngry
                    className={`${
                      selectedEmojis === 'angry' && selectedEmojis.length > 0
                        ? 'emojeIcon'
                        : ''
                    }`}
                  />
                </span>
              </div>
              <p className="mb-2">Did I follow my trading plan?</p>
              <div className="toggle-switch mb-3">
                <div className="can-toggle">
                  <input
                    id="a"
                    type="checkbox"
                    checked={toggleValue === 'No'}
                    onChange={handleToggleChange}
                  />
                  <label htmlFor="a">
                    <div
                      className="can-toggle__switch"
                      data-checked="Yes"
                      data-unchecked="No"
                    ></div>
                  </label>
                </div>
              </div>
              <p className="mb-1">Reflections</p>
              <textarea
                rows={4}
                style={{ padding: '10px' }}
                className="reflections-textarea"
                value={reflections}
                onChange={handleReflections}
              ></textarea>
              <div className="text-end">{wordCount}/250</div>
              <div style={{ height: 20 }}>
                {error && (
                  <p
                    style={{
                      color: 'red',
                      textAlign: 'left',
                      justifyContent: 'center'
                    }}
                  >
                    {error}
                  </p>
                )}
              </div>
              <div className="d-flex align-items-center gap-2 my-2">
                <button
                  className="btn btn-xs btn-secondary"
                  onClick={saveTradeInsight}
                >
                  {' '}
                  {isLoading ? 'Please wait...' : 'Save'}
                </button>
                <button
                  className="btn btn-xs btn-outline-dark"
                  onClick={onCloseInsightModal}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tradeLog-details m-0">
                <div className="tradelog-list">
                  <p>
                    <span
                      className={
                        focusedTrade?.['type'] === TradeSide.BUY
                          ? 'buy'
                          : 'sell'
                      }
                      style={{ display: 'inline-block' }}
                    >
                      {focusedTrade?.['type']}
                    </span>
                  </p>
                  <p>{focusedTrade?.volume}</p>
                  <p>{focusedTrade?.symbol}</p>
                  <p>
                    <b>Entry:</b>
                    <br />
                    {getFormattedDateTime(focusedTrade?.tradeTime)}
                    <br />@{formatPrice(focusedTrade?.openPrice)}
                  </p>
                  <p>
                    <b>Exit:</b>
                    <br />
                    {getFormattedDateTime(focusedTrade?.closingTime)}
                    <br />@{formatPrice(focusedTrade?.currentPrice)}
                  </p>
                </div>
                <h5
                  className={
                    focusedTrade?.currentProfit &&
                    focusedTrade?.currentProfit > 0
                      ? 'green'
                      : 'red'
                  }
                >
                  {forceUnaryOperator(focusedTrade?.currentProfit)}
                </h5>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default HomeComponent;
