import axios from 'axios';
import AppConfig from '../../config';

export const getUserDetails = async (user_id) => {
  const token = localStorage.getItem('jwtToken');

  try {
    const response = await axios.get(AppConfig.apiUrl + '/user/me', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`
      }
    });

    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'An error occurred';

    console.error('Error during sign in:', error);

    throw errorMessage;
  }
};

export const updateProfile = async (user_id, userData) => {
  const token = localStorage.getItem('jwtToken');
  try {
    const response = await axios.patch(
      AppConfig.apiUrl + '/update_profile',
      userData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        },
        params: {
          user_id: user_id
        }
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'An error occurred';
    throw errorMessage;
  }
};
export const TwoFactorAuth = async () => {
  try {
    // Perform API call here
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      AppConfig.apiUrl + '/on_two_factor_authentication',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        }
      }
    );
    // Check if the API call was successful
    return response?.data;
  } catch (error) {
    console.error('Error toggling 2FA:', error);
  }
};
