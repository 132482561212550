import * as React from 'react';

const Logo = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 512 512"
    >
      <g fill="none" stroke="#fff" strokeWidth="32">
        <path
          strokeMiterlimit="10"
          d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
        ></path>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M256 176L256 336"
        ></path>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M336 256L176 256"
        ></path>
      </g>
    </svg>
  );
};

export default Logo;
