import * as React from 'react';

const Logo = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"   {...props}>
    <path fill="currentColor" d="M12 1a11 11 0 1011 11A11.013 11.013 0 0012 1zm0 20a9 9 0 119-9 9.01 9.01 0 01-9 9zM8 11V9a1 1 0 012 0v2a1 1 0 01-2 0zm8-2v2a1 1 0 01-2 0V9a1 1 0 012 0zm-8 5h8a4 4 0 01-8 0z"></path>
  </svg>
  );
};

export default Logo;
