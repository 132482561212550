import * as React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from 'react-router-dom';
import './App.scss';
import PrivateRoute from './privateRoute';
import Login from './features/login/Login';
import HomeComponent from './features/home-screen/component';
import FaAccounts from './features/fa-account/FAaccounts';
import FaTrades from './features/fa-trade/component';
import CreateAccount from './features/create-accounts/component';
import NewAccount from './features/three-steps/component';
import Analysis from './features/fa-analysis/component';
import ActivatedAnalysis from './features/analysis-active/component';
import TradeJournal from './features/fa-journal/component';
import Profile from './features/profile-screen/component';
import Billing from './features/billing-screen/component';
import CreatePassword from './features/create-password/component';
import ForgotPassword from './features/forgot-password/component';
import Otp from './features/otp-screen/component';
import ChangePassword from './features/change-password/component'
import HelpDesk from './features/helpdesk-screen/component';
import Subscription from './features/subscription-screen/component';
import LoginRouteProtect from 'LoginRoute';
import ActivatedSuccess from './features/analysis-active/Analysis-Success';
import AnalysisFailed from './features/analysis-active/Analysis-Failed';
import PrivacyPolicy from './components/common/privacypolicy';
import TermsOfServices from './components/common/termsofservices';
import PageNotFound from 'features/PageNotFound';

export const App = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route index={true} element={<LoginRouteProtect Component={Login} />} />
      <Route
        path="/overview"
        element={<PrivateRoute Component={HomeComponent} />}
      />
      <Route
        path="/overview/:accountId"
        element={<PrivateRoute Component={HomeComponent} />}
      />

      <Route
        path="/accounts"
        element={<PrivateRoute Component={FaAccounts} />}
      />
      <Route path="/trades" element={<PrivateRoute Component={FaTrades} />} />
      <Route
        path="/trades/:accountId"
        element={<PrivateRoute Component={FaTrades} />}
      />
      <Route
        path="/journal"
        element={<PrivateRoute Component={TradeJournal} />}
      />
      <Route
        path="/journal/:accountId"
        element={<PrivateRoute Component={TradeJournal} />}
      />
      <Route path="/create-account" element={<CreateAccount />} />
      <Route
        path="/new-account"
        element={<PrivateRoute Component={NewAccount} />}
      />
      <Route path="/analysis" element={<PrivateRoute Component={Analysis} />} />
      <Route
        path="/analysis/:accountId"
        element={<PrivateRoute Component={Analysis} />}
      />
      <Route path="/helpdesk" element={<PrivateRoute Component={HelpDesk} />} />
      <Route
        path="/subscription/:accountId"
        element={<PrivateRoute Component={Subscription} />}
      />
      <Route path="/profile" element={<PrivateRoute Component={Profile} />} />
      <Route path="/billing" element={<PrivateRoute Component={Billing} />} />
      {/* <Route
        path="/changepassword"
        element={<PrivateRoute Component={ChangePassword} />}
      /> */}

      <Route path="/changepassword" element={<ChangePassword />} />
      <Route path="/createpassword" element={<CreatePassword />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/otp" element={<LoginRouteProtect Component={Otp} />} />
      <Route
        path="/analysis-activated"
        element={<PrivateRoute Component={ActivatedAnalysis} />}
      />
      <Route
        path="/analysis-success/:accountId"
        element={<PrivateRoute Component={ActivatedSuccess} />}
      />
      <Route
        path="/analysis-failed/:accountId"
        element={<PrivateRoute Component={AnalysisFailed} />}
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfServices />} />
      <Route path="*" element={<PageNotFound />} />
    </>
  )
);

export default App;
