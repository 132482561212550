import React, { useState } from 'react';
import eyeShow from '../../assets/Img/eye-show.svg';
import eyeHide from '../../assets/Img/eye-hide.svg';
const PasswordField = ({ field, form, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div style={{display:"flex", alignItems:"center", flexDirection:"row", position:"relative"}}>
      <input
        {...field}
        {...props}
        type={showPassword ? 'text' : 'password'}
       
      />
      <button
        type="button"
        className='password-toggle-btn'
        style={{width:"50px", border:"none", height:"35px", cursor:"pointer", background:"none", position:"absolute", right:"0"}}
        onClick={() => setShowPassword(!showPassword)}
      >
        <img alt='eye' src={showPassword ? eyeShow : eyeHide} />
      </button>
    </div>
  );
};

export default PasswordField;
