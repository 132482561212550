// import { Navigate } from 'react-router-dom';

// const LoginRouteProtect = ({ Component }) => {
//   const token =
//     localStorage.getItem('jwtToken') || localStorage.getItem('twoFa');
//   const isAuthenticated = !!token; // simplify isAuthenticated variable
//   console.log('isAuthenticated', isAuthenticated);
//   // validate token authenticity and expiration (optional)
//   // if (!validateToken(token)) {
//   //   localStorage.removeItem('jwtToken');
//   //   localStorage.removeItem('twoFa');
//   //   return <Navigate to="/" />;
//   // }

//   if (isAuthenticated === false) {
//     return <Navigate to="/" />;
//   }

//   // prevent browser back button from going back to login screen

//   return <Component />;
// };
// export default LoginRouteProtect;
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginRouteProtect = ({ Component }) => {
  const navigate = useNavigate();
  const token =
    localStorage.getItem('jwtToken') || localStorage.getItem('twoFa');
  const isAuthenticated = !!token;
  const token1 = localStorage.getItem('jwtToken');
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    } else if (token1) {
      navigate('/accounts');
    }
  }, [isAuthenticated, navigate, token1]);

  useEffect(() => {
    const handleBackButton = () => {
      navigate('/');
      return false;
    };

    window.onpopstate = handleBackButton;

    return () => {
      window.onpopstate = null;
    };
  }, [navigate]);

  if (isAuthenticated) {
    window.history.forward();
  }

  return <Component />;
};

export default LoginRouteProtect;

// import { Navigate } from 'react-router-dom';

// const LoginRouteProtect = ({ Component }) => {
//   let token = localStorage.getItem('jwtToken');

//   if (token !== null && token !== undefined) {
//     return <Navigate to="/accounts" replace />;
//   } else {
//     return <Component />;
//   }
// };
// export default LoginRouteProtect;
