/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import Header from '../Header/component';
import './styles.scss';
import { useSelector } from 'app/store';
import TradeTable, {
  FilterType,
  FilterValue,
  TradeFilters
} from 'components/trades/tradeTable';
import { Trade } from 'models/trade';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CrossIcon from '../../assets/images/cross-icon.svg';
import { Emotion, SadAngry, Sadface, Smile, Smile2 } from 'assets/svgIcons';
import { forceUnaryOperator, formatPrice } from 'utils/numbers';
import { TradeSide } from 'models/enums/tradeSide';
import Filter from '../../assets/svgIcons/FilterIcon';
import searchicon from '../../assets/images/searchIcon.svg';
import {
  clearChatMessage,
  setCurrentAccount,
  setCurrentLogin
} from '../../components/chatbot/ChatSlice';
import { getTradingPlan } from '../../components/chatbot/tradingPlanSlice';
import ChatbotWebTradePlan from '../../components/chatbot/ChatbotWebTradePlan';
import ChatbotMobileTradePlan from '../../components/chatbot/chatbotMobileTradePlan';
import { io } from 'socket.io-client';

import {
  selectAccounts,
  setUserdetails,
  updateAccounts,
  getDefaultAccount,
  setDefaultAccount,
  addTrade,
  incrementTradesTotal
} from 'features/login/loginSlice';
import NewUserAccount from '../../features/new-user-account/component';
import { useParams } from 'react-router';
import CandlestickChart from './candleStickChart';
import { useDispatch } from 'react-redux';
import { getUserDetails } from 'features/profile-screen/userApi';
import { getHistoricalData } from './api/alphavintage';
import { Dropdown, Space, Typography } from 'antd';
import { AppDispatch } from '../../app/store';

const Helper = require('../../utils/helper');

const URL = 'wss://api.fractalalpha.com/deals';

const FaTradeHistory = () => {
  const DefaultAccount = useSelector(getDefaultAccount);

  const [IsNewUser, setNewUser] = useState(false);
  const [InitiateModal, setInitiateModal] = useState(true);
  const InitiateModaltoggle = () => setInitiateModal(!InitiateModaltoggle);
  const [filters, setFilters] = useState<TradeFilters>();
  const dispatch = useDispatch<AppDispatch>();
  const allAccounts = useSelector(selectAccounts);

  const [stockData, setStockData] = useState([]);
  const [stockDataLoading, setStockDataLoading] = useState(false);
  const accounts = allAccounts?.filter(
    (account) => account.status === 'active'
  );
  const [symbol, setSymbol] = useState<string>('');
  const [value, setValue] = useState('');

  const [insights, setInsights] = useState({
    emoji_reaction: '',
    follow_trading_plan: '',
    reflection: '',
    trade_id: '',
    _id: ''
  });

  const { accountId } = useParams();

  const [selectedAccount, setSelectedAccount] = useState<any>(
    DefaultAccount
      ? DefaultAccount
      : accountId ||
          (accounts && accounts.length > 0 ? accounts[0].account_id : null)
  );

  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(
    'ALL'
  );
  const [selectedLogin, setSelectedLogin] = useState('');
  const [isMobile, setIsMobile] = useState('');

  useEffect(() => {
    const selected_account = accounts.find(
      (account) => account.account_id === selectedAccount
    );
    if (selected_account) {
      dispatch(setCurrentAccount(selected_account.account_id));
      dispatch(setCurrentLogin(selected_account.login));
      setSelectedLogin(selected_account.login);
    }
  }, [accountId, accounts, selectedAccount]);

  useEffect(() => {
    const socket = io(URL, {
      query: { login: selectedLogin },
      reconnectionAttempts: 5, // Attempt to reconnect up to 5 times
      reconnectionDelay: 2000 // Delay between reconnection attempts
    });

    socket.on('connect', () => {
      console.log('WebSocket connected');
    });

    socket.on('disconnect', () => {
      console.log('WebSocket disconnected');
    });

    socket.on('error', (error) => {
      console.error('WebSocket error:', error);
    });

    socket.on('new_trade', (trade) => {
      if (trade) {
        dispatch(addTrade(trade));
        dispatch(incrementTradesTotal());
        dispatch(getTradingPlan(selectedAccount));
      }
    });

    return () => {
      socket.close();
      console.log('WebSocket connection closed');
    };
  }, [selectedLogin]);

  useEffect(() => {
    if (selectedAccount) {
      dispatch(getTradingPlan(selectedAccount));
    }
  }, [selectedAccount]);

  const handleFilterSelect = (filterType) => {
    const newFilter = selectedFilter === filterType ? '' : filterType;
    setSelectedFilter(newFilter);
    onFilterChange(FilterType.TRADE_TYPE, newFilter);
  };

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    Helper.isTokenExpired(token);
  }, []);

  useEffect(() => {
    setNewUser(Helper.checkIfNewUser(allAccounts));
  }, [allAccounts]);

  useEffect(() => {
    if (accounts && accounts.length === 0) {
      fetchUser();
    }
  }, []);

  const fetchUser = async () => {
    try {
      const data = await getUserDetails();
      const select = data?.accounts.filter(
        (account) => account.status === 'active'
      );
      if (!accountId) {
        setSelectedAccount(select[0]?.account_id);
        setDefaultAccount(select[0]?.account_id);
      } else {
        dispatch(setDefaultAccount(accountId));
        setSelectedAccount(accountId);
      }

      dispatch(setUserdetails(data));
      dispatch(updateAccounts(data?.accounts || []));
    } catch (error) {}
  };

  const onFilterChange = <T extends FilterType>(
    type: T,
    value: FilterValue<T> | undefined
  ) => {
    setFilters((prev) => ({ ...prev, [type]: value }));
    if (value !== undefined && typeof value === 'string') {
      setSymbol(value);
    }
  };

  const { RangePicker } = DatePicker;

  const [detailsModal, setDetailsModal] = useState<{
    show: boolean;
    trade?: Trade;
  }>({ show: false });

  const showDetails = async (trade) => {
    setDetailsModal({ show: true, trade });
    if (trade?.insights_details) {
      setInsights(trade?.insights_details);
    }
    if (!trade?.graph_data || trade?.graph_data.length === 0) {
      const formattedDateStart = trade?.tradeTime
        .replace('.', '-')
        .replace('.', '-');
      const formattedDateEnd = trade?.closingTime
        .replace('.', '-')
        .replace('.', '-');

      try {
        setStockDataLoading(true);
        const data = await getHistoricalData(
          trade.symbol,
          formattedDateStart,
          formattedDateEnd,
          trade?._id
        );

        setStockData(data?.graph_data ?? []);
        setStockDataLoading(false);
      } catch (error) {
        setStockData([]);
        setStockDataLoading(false);
      }
    } else {
      setStockData(trade?.graph_data ?? []);
    }

    // getTradeInsights(trade._id);
  };

  const onCloseDetailModal = () => {
    setInsights({
      emoji_reaction: '',
      follow_trading_plan: '',
      reflection: '',
      trade_id: '',
      _id: ''
    });
    setStockData([]);
    setDetailsModal((prev) => ({ ...prev, show: false }));
  };

  const focusedTrade = detailsModal.trade;

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Check if the input value length is 6 or less
    if (inputValue.length <= 6) {
      setValue(inputValue);
      onFilterChange(FilterType.STRING, inputValue);
    }
  };

  const items = [
    {
      key: '1',
      label: 'ALL',
      onClick: () => handleFilterSelect('ALL')
    },
    {
      key: '2',
      label: 'BUY',
      onClick: () => handleFilterSelect('BUY')
    },
    {
      key: '3',
      label: 'SELL',
      onClick: () => handleFilterSelect('SELL')
    }
  ];

  const selectedItem = items.find((item) => item.label === selectedFilter);
  const selectedKey = selectedItem ? selectedItem.key : undefined;

  return (
    <>
      <Header activeLink={'trades'} />
      {IsNewUser ? (
        <NewUserAccount
          InitiateModal={InitiateModal}
          InitiateModaltoggle={InitiateModaltoggle}
        />
      ) : (
        <div className="main_page">
          <div className="left_container">
            <div className="main--container">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 p-0">
                    <div className="table-container">
                      <div className="table-sub-container">
                        <div className="main-heading">
                          <p>Trade Matrix</p>
                        </div>
                        <div className="fa-filter-block">
                          <div className="fa-filter">
                            <div className="account-feild">
                              <select
                                className="form-select"
                                defaultValue={
                                  accountId ? accountId : DefaultAccount
                                }
                                onChange={(e) => {
                                  const selectAccounts = e.target.value;
                                  dispatch(setDefaultAccount(selectAccounts));
                                  if (accountId) {
                                    window.history.pushState(
                                      {},
                                      '',
                                      `/trades/${e.target.value}`
                                    );
                                  }
                                  setSelectedAccount(selectAccounts);
                                  dispatch(clearChatMessage(true));
                                }}
                              >
                                {accounts.map((account) => (
                                  <option
                                    key={account.account_id}
                                    value={account.account_id}
                                    selected={
                                      account.account_id ===
                                      (DefaultAccount || accountId)
                                    }
                                  >
                                    Account #{account.account_id}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="date-picker-search">
                              <div className="search-feild">
                                <input
                                  type="search"
                                  placeholder="Symbol"
                                  className="form-control"
                                  value={value}
                                  onChange={handleChange}
                                />
                                <img alt="searchicon" src={searchicon} />
                              </div>
                              <div className="datepicker-block">
                                <div className="daterange-picker">
                                  <Space direction="vertical" size={12}>
                                    <RangePicker
                                      onChange={(values) => {
                                        if (!values) return;
                                        if (!values[0] || !values[1] || !values)
                                          return;
                                        onFilterChange(FilterType.DATE, {
                                          start: values[0].format('YYYY.MM.DD'),
                                          end: values[1].format('YYYY.MM.DD')
                                        });
                                      }}
                                      onCalendarChange={(
                                        values,
                                        formatString
                                      ) => {
                                        if (!values) {
                                          onFilterChange(FilterType.DATE, {
                                            start: '',
                                            end: ''
                                          });
                                        }
                                      }}
                                      placeholder={['Start', 'End']}
                                    />
                                  </Space>
                                </div>
                                <div className="filter-dropdown dropdown">
                                  <div className="filterIcon">
                                    <Dropdown
                                      menu={{
                                        items,
                                        selectable: true,
                                        selectedKeys: selectedKey
                                          ? [selectedKey]
                                          : ['1']
                                      }}
                                    >
                                      <Typography.Link>
                                        <Space>
                                          <Filter
                                            className={
                                              selectedFilter === 'BUY' ||
                                              selectedFilter === 'SELL'
                                                ? 'filter-icon'
                                                : 'filter-disable-icon'
                                            }
                                          />
                                        </Space>
                                      </Typography.Link>
                                    </Dropdown>

                                    {/* <span className="filtercounter">
                                      {selectedFilter &&
                                      selectedFilter !== 'ALL'
                                        ? 1
                                        : 0}
                                    </span> */}
                                  </div>

                                  {/* <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li>
                                      <label
                                        className={`dropdown-item ${
                                          selectedFilter === 'BUY'
                                            ? 'selected-buy'
                                            : ''
                                        }`}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={selectedFilter === 'BUY'}
                                          onChange={() =>
                                            handleFilterSelect('BUY')
                                          }
                                        />
                                        Buy
                                      </label>
                                    </li>
                                    <li>
                                      <label
                                        className={`dropdown-item ${
                                          selectedFilter === 'SELL'
                                            ? 'selected-sell'
                                            : ''
                                        }`}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={selectedFilter === 'SELL'}
                                          onChange={() =>
                                            handleFilterSelect('SELL')
                                          }
                                        />
                                        Sell
                                      </label>
                                    </li>
                                  </ul> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <TradeTable
                          accountNo={selectedAccount}
                          appliedFilters={filters}
                          onRowPress={(trade) => showDetails(trade)}
                        />
                      </div>
                    </div>
                    <div>
                      {
                        <ChatbotMobileTradePlan
                          from={'account'}
                          selectedAccount={selectedAccount}
                          selectedLogin={selectedLogin}
                          // isMobile ={isMobile}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right_container">
            <ChatbotWebTradePlan
              from={'account'}
              selectedAccount={selectedAccount}
              selectedLogin={selectedLogin}
            />
          </div>
        </div>
      )}
      <Modal
        isOpen={detailsModal.show}
        className="trades-details-modal"
        size="lg"
        centered
        toggle={onCloseDetailModal}
        onClosed={onCloseDetailModal}
      >
        <ModalHeader className="border-0 pb-0">
          Trade Log
          <img
            alt="cross"
            src={CrossIcon}
            style={{
              width: '20px',
              padding: '5px',
              float: 'right',
              cursor: 'pointer'
            }}
            onClick={onCloseDetailModal}
          />
        </ModalHeader>
        <ModalBody className="pb-0">
          <div className="trade-flex">
            <div className="left-trade">
              <div className="tradeLog-details">
                <div className="tradelog-list">
                  <p>
                    <span
                      className={
                        focusedTrade?.['type'] === TradeSide.BUY
                          ? 'buy'
                          : 'sell'
                      }
                      style={{ display: 'inline-block' }}
                    >
                      {focusedTrade?.['type']}
                    </span>
                  </p>
                  <div className="tradelog-item">
                    <span>{focusedTrade?.volume}</span>
                    <span>{focusedTrade?.symbol}</span>
                  </div>
                  <div className="tradelog-item">
                    <p>
                      <b>Entry:</b>
                    </p>
                    <span>{focusedTrade?.tradeTime}</span>
                    <span>@{formatPrice(focusedTrade?.openPrice)}</span>
                  </div>
                  <div className="tradelog-item">
                    <p>
                      <b>Exit:</b>
                    </p>
                    <span>{focusedTrade?.closingTime}</span>
                    <span>@{formatPrice(focusedTrade?.currentPrice)}</span>
                  </div>
                </div>
                <h5
                  className={
                    focusedTrade?.currentProfit &&
                    focusedTrade?.currentProfit > 0
                      ? 'green'
                      : 'red'
                  }
                >
                  {forceUnaryOperator(focusedTrade?.currentProfit)}
                </h5>
              </div>
              <p className="mb-2">Did I follow my trading plan?</p>
              <div className="toggle-switch">
                <div className="can-toggle">
                  <input
                    id="a"
                    type="checkbox"
                    checked={
                      insights?.follow_trading_plan !== 'Yes' ? true : false
                    }
                  />
                  <label htmlFor="a">
                    <div
                      className="can-toggle__switch"
                      data-checked="Yes"
                      data-unchecked="No"
                    ></div>
                  </label>
                </div>
              </div>
            </div>
            <div className="center-trade">
              <p className="mb-2">How did I feel?</p>
              <div className="check-emoje">
                <span
                  className={`emoje ${
                    insights?.emoji_reaction === 'laugh' ? 'selected' : ''
                  }`}
                >
                  <Smile
                    className={`${
                      insights?.emoji_reaction === 'laugh' ? 'emojeIcon' : ''
                    }`}
                  />
                </span>
                <span
                  className={`emoje ${
                    insights?.emoji_reaction === 'smile' ? 'selected' : ''
                  }`}
                >
                  <Smile2
                    className={
                      insights?.emoji_reaction === 'smile' ? 'emojeIcon' : ''
                    }
                  />
                </span>
                <span
                  className={`emoje ${
                    insights?.emoji_reaction === 'speechless' ? 'selected' : ''
                  }`}
                >
                  <Emotion
                    className={
                      insights?.emoji_reaction === 'speechless'
                        ? 'emojeIcon'
                        : ''
                    }
                  />
                </span>
                <span
                  className={`emoje ${
                    insights?.emoji_reaction === 'sad' ? 'selected' : ''
                  }`}
                >
                  <Sadface
                    className={
                      insights?.emoji_reaction === 'sad' ? 'emojeIcon' : ''
                    }
                  />
                </span>
                <span
                  className={`emoje ${
                    insights?.emoji_reaction === 'angry' ? 'selected' : ''
                  }`}
                >
                  <SadAngry
                    className={
                      insights?.emoji_reaction === 'angry' ? 'emojeIcon' : ''
                    }
                  />
                </span>
              </div>
              <CandlestickChart
                stockData={stockData}
                stockDataLoading={stockDataLoading}
              />
            </div>
            {/* <div
              className="right-trade"
              style={{ borderLeft: '1px solid #ccc' }}
            ></div> */}
          </div>
        </ModalBody>
        <ModalFooter className="border-0 justify-content-start">
          {Object.keys(insights)?.length !== 0 &&
            insights.reflection !== '' && (
              <div className="w-100">
                <p className="mb-2">Reflections</p>
                <p className="reflection--text ">{insights?.reflection}</p>
              </div>
            )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FaTradeHistory;
