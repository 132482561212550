import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import AppConfig from '../../config';

export const fetchTradeData = createAsyncThunk('fetchTradeData', async () => {
  var token = localStorage.getItem('jwtToken');
  try {
    const response = await axios.get(
      `https://api.fractalalpha.com/get_trades_page?account_id=FA46650498&page=1&page_size=5`,
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    console.error('Error during getTrade:', error);
    throw errorMessage;
  }
});

export const addTradeInsight = async (tradeId, insights) => {
  try {
    var token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      AppConfig.apiUrl + `/trade_insights?trade_id=${tradeId}`,
      insights,
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    throw errorMessage;
  }
};

export const getCalculations = createAsyncThunk(
  'home/getCalculations',
  async ({ selectedAccount, day }) => {
    const token = localStorage.getItem('jwtToken');
    try {
      const response = await axios.get(
        `${AppConfig.apiUrl}/calculations_for_days?account_id=${selectedAccount}&days=${day}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      console.error('Error during getting user details:', error);
      return errorMessage;
    }
  }
);
