import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import AppConfig from '../../config';

export const getUserDetails = createAsyncThunk(
  'users/getUserDetails',
  async () => {
    const token = localStorage.getItem('jwtToken');
    try {
      const response = await axios.get(AppConfig.apiUrl + '/user/me', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        }
      });
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      console.error('Error during getting user details:', error);
      return errorMessage;
    }
  }
);
export const sendOtp = createAsyncThunk(
  'users/sendOtp',

  async (formikValues, thunkAPI) => {
    try {
      const response = await axios.post(
        AppConfig.apiUrl + '/signin',
        formikValues
      );

      if (response?.data?.data === undefined) {
        const twoFa = response?.data?.twoFA_value;
        localStorage.setItem('twoFa', twoFa);
        return response;
      } else {
        const token = response?.data?.data?.token;
        localStorage.setItem('jwtToken', token);

        return response.data;
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      console.error('Error during sign in:', error);
      throw errorMessage;
    }
  }
);
export const signIn = createAsyncThunk(
  'users/signIn',

  async (otpValue) => {
    try {
      const response = await axios.post(
        AppConfig.apiUrl + '/verify_signin_otp',
        otpValue
      );
      const token = response?.data?.data?.token;
      localStorage.setItem('jwtToken', token);

      // Serialize the array into a string using JSON.stringify
      // Store the serialized array in local storage
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      console.error('Error during sign in:', error);
      throw errorMessage;
    }
  }
);
