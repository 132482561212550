import React from 'react';

export function dateDifference(_startDate: string, _endDate: string) {
  const startDate = new Date(_startDate);
  const endDate = new Date(_endDate);
  const millisecondsDiff = endDate.getTime() - startDate.getTime();
  const seconds = Math.floor(millisecondsDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const years = endDate.getFullYear() - startDate.getFullYear();
  const months = endDate.getMonth() - startDate.getMonth();

  const remainingMonths = months < 0 ? 12 + months : months;
  const remainingYears = months < 0 ? years - 1 : years;
  const remainingHours = hours % 24;
  const remainingMins = minutes % 60;
  const remaininSecs = seconds % 60;

  const res: string[] = [];
  if (remainingYears > 0) res.push(`${remainingYears}Y`);
  if (remainingMonths > 0) res.push(`${remainingMonths}M`);
  if (days > 0) res.push(`${days}D`);
  if (remainingHours > 0) res.push(`${remainingHours}h`);
  if (remainingMins > 0) res.push(`${remainingMins}m`);
  if (remaininSecs > 0) res.push(`${remaininSecs}s`);
  return res.length === 0 ? '-' : res.join(', ');
}

export const getFormattedDateTime = (
  date: string | undefined,
  addBreakBWDateAndTime: boolean = false
) => {
  if (!date) return '';

  const dateTimeString = new Date(date).toLocaleString(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });

  const [datePart, timePart] = dateTimeString.split(', ');

  return (
    <span>
      {datePart}
      {addBreakBWDateAndTime ? <br /> : ' '}
      {timePart}
    </span>
  );
};

export const isSameDay = (
  date: string | Date,
  toCompare: string | Date
): boolean => {
  const parsedDate = typeof date === 'string' ? new Date(date) : date;
  const parsedToCompare =
    typeof toCompare === 'string' ? new Date(toCompare) : toCompare;

  return (
    parsedDate.getFullYear() === parsedToCompare.getFullYear() &&
    parsedDate.getMonth() === parsedToCompare.getMonth() &&
    parsedDate.getDate() === parsedToCompare.getDate()
  );
};

export const formatAsParam = (_date: Date | string) => {
  if (!_date) return null;

  let date = _date;
  if (typeof date === 'string') date = new Date(date);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString(); // Use getFullYear() without slicing
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  
  return `${year}.${month}.${day} ${hours}:${minutes}`;
};
