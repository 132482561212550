import React from 'react';

const User = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="20"
    fill="none"
    viewBox="0 0 16 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M0 20a7.619 7.619 0 1115.238 0h-1.905a5.714 5.714 0 10-11.428 0H0zm7.619-8.571a5.713 5.713 0 01-5.714-5.715A5.713 5.713 0 017.619 0a5.713 5.713 0 015.714 5.714A5.713 5.713 0 017.62 11.43zm0-1.905a3.808 3.808 0 003.81-3.81 3.808 3.808 0 00-3.81-3.81 3.808 3.808 0 00-3.81 3.81 3.808 3.808 0 003.81 3.81z"
    ></path>
  </svg>
);

export default User;
