import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { checkPaymentStatus } from 'features/fa-account/accountApi';
import { selectAccounts } from 'features/login/loginSlice';
import { useSelector } from 'app/store';

function CustomModal({ InitiateModal, InitiateModaltoggle }) {
  const navigate = useNavigate();
  const accounts = useSelector(selectAccounts);
  const [paymentChecking, setPaymentChecking] = useState();
  const initiateAIAnalysis = async (account_id) => {
    try {
      setPaymentChecking(true);
      const res = await checkPaymentStatus(account_id);
      if (res.status === 'completed') {
        navigate(`/analysis-success/account_id=${account_id}`);
        setPaymentChecking(false);
      } else {
        navigate(`/new-account?account_id=${account_id}`);
        setPaymentChecking(false);
      }
    } catch (error) {
      setPaymentChecking(false);
    }
  };
  return (
    <Modal
      isOpen={InitiateModal}
      toggle={InitiateModaltoggle}
      centered
      className="new-account-modal"
    >
      <ModalHeader toggle={InitiateModaltoggle}>New Account</ModalHeader>
      <ModalBody>
        <div className="initiateModalBody">
          <h1>Kudos on leveling up your trading!</h1>
          <p>
            Now, let's kick it up a notch by diving into the data analysis of
            your past trades.
          </p>
          <div className="text-center">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                initiateAIAnalysis(accounts[0]?.account_id);
              }}
            >
              {paymentChecking ? 'Please wait...' : '     Initiate Analysis'}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default CustomModal;
