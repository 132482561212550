import * as React from 'react';

const Logo = ({ ...props }) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 256 256"
   {...props}
  >
    <path
      fill="currentColor"
      strokeMiterlimit="10"
      strokeWidth="0"
      d="M52.537 80.466V45.192L84.53 2.999C85.464 1.768 84.586 0 83.041 0H6.959C5.414 0 4.536 1.768 5.47 2.999l31.994 42.192v43.441a1.365 1.365 0 002.073 1.167l11.758-7.127a2.581 2.581 0 001.242-2.206z"
      transform="matrix(2.81 0 0 2.81 1.407 1.407)"
    ></path>
  </svg>
  );
};

export default Logo;
