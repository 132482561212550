import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import AppConfig from '../../config';
import moment from 'moment';

type TradeJournalData = {
  dateSummary: [];
  account_id:''
};

type EventType = {
  date: string;
  number_of_trades: number;
  total_profit: number;
};

type formattedEventsType = {
  title: string;
  start: Date; // You could also use `Moment` if you prefer to work with Moment.js types
  end: Date;
  total_profit: number;
  number_of_trades: number;
};

// Define the argument type for the thunk
type GetAllTradeJournalArgs = {
  start_date?: string;
};

// Keep the return type of createAsyncThunk as a single TradeJournalData object
export const getAllTradeJournal = createAsyncThunk<
  TradeJournalData, // Return type as a single object
  GetAllTradeJournalArgs, // Argument type
  { rejectValue: string } // Reject value type
>(
  'tradesjournal/getAllTradeJournal',
  async (accountId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        return rejectWithValue('No token found');
      }
      const response = await axios.get(
        `${AppConfig.apiUrl}/trades_journal?account_id=${accountId}`,
        {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      let errorMessage = 'An error occurred';
      if (error instanceof AxiosError && error.response) {
        errorMessage = error.response.data?.message || errorMessage;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

// Keep the state type the same
type TradeJournalDataType = {
  allTradeJournal: []; // Still an array of TradeJournalData
  events: formattedEventsType[];
  account_id:string;
  isLoading: boolean;
  error: string | null;
};

// Initial state remains the same
const initialState: TradeJournalDataType = {
  allTradeJournal: [],
  events: [],
  account_id:'',
  isLoading: false,
  error: null
};

// Update the fulfilled case to handle a single object
const tradesJournalSlice = createSlice({
  name: 'tradesjournal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTradeJournal.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllTradeJournal.fulfilled, (state, action) => {
        state.isLoading = false
        state.allTradeJournal = action.payload.dateSummary;
        state.account_id = action.payload.account_id
        if (action.payload.dateSummary) {
          const formattedEvents: formattedEventsType[] =
            action.payload.dateSummary.map((event: EventType) => {
              return {
                title: `Trades: ${event.number_of_trades}, Profit: ${event.total_profit}`,
                start: moment(event.date, 'YYYY.MM.DD').toDate(),
                end: moment(event.date, 'YYYY.MM.DD').toDate(),
                total_profit: event.total_profit,
                number_of_trades: event.number_of_trades
              };
            });
          state.events = formattedEvents;
        }
      })
      .addCase(getAllTradeJournal.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload as string; // Set the error message if any
      });
  }
});

export default tradesJournalSlice.reducer;
