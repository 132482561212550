import { BackendObj, ApiMapping } from '../interfaces/apiMiddleware';

type DeserializationResult<T> = T | T[];

const deserialize = <T>(
  inputObj: BackendObj,
  inputObjMapping: ApiMapping | undefined
): DeserializationResult<T> => {
  // If there's no mapping or the current input object is null or undefined, just return the value
  if (!inputObjMapping || !inputObj) {
    return inputObj as DeserializationResult<T>;
  }

  const resultObj: Record<string, any> = {};

  // Iterate through mapping keys
  Object.keys(inputObjMapping).forEach((fromKey) => {
    const currField = inputObj[fromKey];
    const currFieldMapping = inputObjMapping[fromKey];

    // Value is not defined
    if (currField === undefined) {
      // if (currFieldMapping.required) {
      //   console.error(
      //     `Field ${fromKey} was required in mapping, but was undefined`,
      //   );
      // }
      return;
    }

    // Nested mapping
    if (currFieldMapping.isObject) {
      // Recursively deserialize
      resultObj[currFieldMapping.to] = deserialize(
        currField,
        currFieldMapping.mappings
      );
    }
    // Array
    else if (currFieldMapping.isArray) {
      // Expected Array, but received something else
      if (!Array.isArray(currField)) {
        // console.error(
        //   `Expected field ${fromKey} to be of type Array. Received: ${typeof currField}`,
        // );
        return;
      }

      // Recursively deserialize all array items
      resultObj[currFieldMapping.to] = currField.map((el) =>
        deserialize(el, currFieldMapping.mappings)
      );
    }
    // Primitive value or map
    else {
      const transform = inputObjMapping[fromKey].transform;
      // If it's a map, transform each key
      if (currFieldMapping.isMap) {
        resultObj[currFieldMapping.to] = Object.keys(currField).reduce(
          (acc: Record<string, any>, key: string) => {
            return {
              ...acc,
              [key]: transform ? transform(currField[key]) : currField[key]
            };
          },
          {}
        ) as Record<string, any>;
      }
      // Otherwise, just transform the value
      else {
        resultObj[currFieldMapping.to] = transform
          ? transform(currField)
          : currField;
      }
    }
  });

  return resultObj as DeserializationResult<T>;
};

export const deserializeSingle = <T>(
  data: BackendObj,
  mapping: ApiMapping
): T => {
  return deserialize(data, mapping) as T;
};

export const deserializeArray = <T>(
  dataArray: BackendObj[],
  mapping: ApiMapping
): T[] => {
  return dataArray.map((el) => deserialize(el, mapping)) as T[];
};
