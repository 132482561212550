import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ Component }) => {
  const token = localStorage.getItem('jwtToken');
  const isAuthenticated = !!token;

  return isAuthenticated ? <Component /> : <Navigate to="/" replace={true} />;
};

export default PrivateRoute;
