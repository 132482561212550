import React, { useState } from 'react';
import brandLogo from '../../assets/images/logo.png';
import axios from 'axios';
import * as Yup from 'yup';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [backendMessage, setBackendMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});

  // Validation schema
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required')
  });

  // Validate form manually
  const validateForm = async () => {
    try {
      await validationSchema.validate({ email }, { abortEarly: false });
      return true;
    } catch (validationErrors) {
      const formErrors = {};
      validationErrors.inner.forEach((error) => {
        formErrors[error.path] = error.message;
      });
      setErrors(formErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous errors
    setErrors({});
    setErrorMessage('');
    setBackendMessage('');

    const isValid = await validateForm();
    if (!isValid) return;

    try {
      setButtonDisabled(true);
      const response = await axios.post(
        `https://api.fractalalpha.com/forget_password`,
        { email }
      );
      setBackendMessage(response.data.message);
      setButtonDisabled(false);
    } catch (error) {
      const backendErrorMessage =
        error.response?.data?.message || 'An error occurred';

      setErrorMessage(backendErrorMessage);
      setButtonDisabled(false);
    }
  };

  return (
    <>
      <div className="login-wrapper ">
        <div className="loginBlock">
          <div className="brandLogo">
            <img src={brandLogo} alt="Brand Logo" />
          </div>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="sign-title">Forgot Password?</div>
            <p className="mb-3">
              Enter the email address you used when you joined and we’ll send
              you instructions to reset your password.
            </p>
            <div>
              <label className="form-label">Email address</label>
              <input
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setButtonDisabled(false);
                  setErrorMessage('');
                  setBackendMessage('');
                }}
                type="email"
                className="form-control"
              />
              <div
                className="error-message"
                style={{ height: 15, marginTop: 2 }}
              >
                {errors.email ? errors.email : null}
              </div>
            </div>

            {backendMessage && <p className="successmsg">{backendMessage}</p>}
            {errorMessage && (
              <div className="error-message" style={{ color: 'red' }}>{errorMessage}</div>
            )}

            <div className="mt-3">
              <button
                type="submit"
                disabled={isButtonDisabled}
                className="btn btn-sm btn-primary w-100"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;




// import React, { useState } from 'react';
// import brandLogo from '../../assets/images/logo.png';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import axios from 'axios';

// const ForgotPassword = (props) => {
//   const [isButtonDisabled, setButtonDisabled] = useState(false);
//   const [successMessage, setSuccessMessage] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');

//   const validationSchema = Yup.object({
//     email: Yup.string()
//       .email('Invalid email address')
//       .required('Email is required')
//   });

//   const formik = useFormik({
//     initialValues: {
//       email: ''
//     },
//     validationSchema,
//     onSubmit: async (values) => {
//       try {
//         setButtonDisabled(true);
//         setErrorMessage('')
//         const response = await axios.post(
//           https://api.fractalalpha.com/forget_password,
//           { email: values.email }
//         );
//         setSuccessMessage('Password Reset Link Sent Successfully!.');
//       } catch (error) {
//         console.log(error,"errror");
//         const errorMessage = error.response?.data?.message || 'An error occurred';
//         console.error('Error during getting user details:', error);
//         throw errorMessage;
// if (error.response && error.response.status === 400) {
//   setErrorMessage('Invalid email address. Please enter a valid email.');
// } else {
//   setErrorMessage(
//     'An error occurred while processing your request. Please try again later.'
//   );
//   setButtonDisabled(false);
// }
//       }
//     }
//   });

//   return (
//     <>
//       <div className="login-wrapper ">
//         <div className="loginBlock">
//           <div className="brandLogo">
//             <img src={brandLogo} alt="Brand Logo" />
//           </div>
//           <form className="login-form" onSubmit={formik.handleSubmit}>
//             <div class="sign-title">Forgot Password?</div>
//             <p className="mb-3">
//               Enter the email address you used when you joined and we’ll send
//               you instructions to reset your password.
//             </p>
//             <div>
//               <label className="form-label">Email address</label>
//               <input
//                 name="email"
//                 value={formik.values.email}
//                 onChange={(e) => {
//                   formik.handleChange(e);
//                   setButtonDisabled(false);
//                   setErrorMessage('');
//                   setSuccessMessage('');
//                 }}
//                 onBlur={formik.handleBlur}
//                 type="email"
//                 className="form-control"
//               />
//               <div
//                 className="error-message"
//                 style={{ height: 15, marginTop: 2 }}
//               >
//                 {formik.touched.email && formik.errors.email
//                   ? formik.errors.email
//                   : null}
//               </div>
//             </div>

//             {successMessage && <p className="successmsg">{successMessage}</p>}
//             {errorMessage && (
//               <div className="error-message">{errorMessage}</div>
//             )}

//             <div className="mt-3">
//               <button
//                 type="submit"
//                 disabled={isButtonDisabled}
//                 className="btn btn-sm btn-primary w-100"
//               >
//                 Confirm
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ForgotPassword;