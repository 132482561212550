import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIsMobile } from '../../utils/helper';
import chatIcon from '../../assets/Img/chat-icon.svg';
import ChatbotWeb from './ChatbotWeb';
import { clearChatMessage, setSelectedAccount } from './ChatSlice';
import {
  setDefaultAccount,
  getDefaultAccount,selectUserName
} from '../../features/login/loginSlice';


function ChatbotMobile(props) {
  
  const userName = useSelector(selectUserName);
  const isMobile = useIsMobile();
  const { from, activeAccounts } = props;
  const key = from === 'analysis' ? 'analysis' : '';
  const dispatch = useDispatch();
  const {
    chatMessages,
    selectedAccount,
    selectedLogin,
    currentAccount,
    currentLogin
  } = useSelector((state) => state.chatbot);
  const [chatMessage, setChatMessage] = useState('');
  
  const defaultAccount = useSelector(getDefaultAccount);

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (activeAccounts?.length === 1 && !selectedAccount) {
      const singleAccount = activeAccounts[0];
      dispatch(
        setSelectedAccount({
          accountId: singleAccount.account_id,
          login: singleAccount.login
        })
      );
    } else if (activeAccounts?.length > 1 && defaultAccount !== null) {
      setIsDisabled(false);
      const selectedAccount = activeAccounts.find(
        (account) => account.account_id === defaultAccount
      );
      if (selectedAccount) {
        dispatch(
          setSelectedAccount({
            accountId: selectedAccount.account_id,
            login: selectedAccount.login
          })
        );
      }
    }
  }, [activeAccounts, selectedAccount, dispatch, defaultAccount]);

  const handleSelectChange = (event) => {
    const accountId = event.target.value;
    setIsDisabled(false);
    const selectedAccount = activeAccounts.find(
      (account) => account.account_id === accountId
    );
    dispatch(clearChatMessage(true));
    if (selectedAccount) {
      dispatch(
        setSelectedAccount({
          account_id: currentAccount,
          login: currentLogin,
          message: chatMessage,
          name: userName,
          type: 'bot',
          key: key
        })
      );
      dispatch(setDefaultAccount(selectedAccount.account_id));
    }
  };

  return (
    <div>
      {from === 'account' && isMobile && (
        <div className="chat-header">
          {activeAccounts && activeAccounts.length > 0 && (
            <div className="chat-footer-dropdown" style={{ padding: 0 }}>
              <div className="d-flex align-items-center justify-content-between gap-3">
                <div>
                  {activeAccounts.length === 1 ? (
                    <div style={{ color: 'white' }}>
                      Account #{activeAccounts[0].account_id}
                    </div>
                  ) : (
                    <select
                      className="form-select"
                      value={selectedAccount?.account_id || ''}
                      onChange={handleSelectChange}
                      disabled={isDisabled}
                    >
                      <option value="">Please select fractal account</option>
                      {activeAccounts.map((account) => (
                        <option
                          key={account.account_id}
                          value={account.account_id}
                        >
                          Account #{account.account_id}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {isMobile && (
        <button
          className="chat--icon"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <img alt="chatIcon" src={chatIcon} />
        </button>
      )}
      <div
        className="offcanvas mobile-view-chatboat offcanvas-end offcanvasWidth"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body p-0">
          <ChatbotWeb
            from={'account'}
            selectedLogin={selectedLogin}
            activeAccounts={activeAccounts}
          />
        </div>
      </div>
    </div>
  );
}

export default ChatbotMobile;
