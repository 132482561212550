import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import SuccessIcon from '../../assets/Img/success-icon.svg';
import axios from 'axios';

const ChangePassword = (props) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .min(8, 'Must be at least 8 characters')
      .required('New password is required'),
    confirmPassword: Yup.string()
      .min(8, 'Must be at least 8 characters')
      .required('Confirm password is required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema,

    onSubmit: async (values) => {
      try {
        await axios.post('YOUR_API_ENDPOINT', {
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword
        });

        setSuccessMessage('Password updated successfully');
        navigate('/ ');
      } catch (error) {
        setErrorMessage(
          'An error occurred while updating your password. Please try again later.'
        );
      }
    }
  });

  return (
    <>
      <div className="login-wrapper">
        <div className="loginBlock">
          <div className="login-form">
            <div>
              <img
                alt="SuccessIcon"
                src={SuccessIcon}
                className="success-icon"
              />
            </div>
            <div class="sign-title mb-0">Password Changed Successfully!</div>
            <p>Please login to your email account again</p>
            <button className="btn btn-primary btn-sm mt-3 w-100">
              Login Now{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
