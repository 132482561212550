import * as React from 'react';

const Logo = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="currentColor"
        d="M22 16v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4h2v4h16v-4h2zm-9-3.414l3.293-3.293 1.414 1.414L12 16.414l-5.707-5.707 1.414-1.414L11 12.586V2h2v10.586z"
      ></path>
    </svg>
  );
};

export default Logo;
