// We dont need this now, but this setup should be good post launch

const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

interface AppConfigType {
  apiUrl: string;
}

const AppConfig: AppConfigType = {
  apiUrl: API_URL || 'https://api.fractalalpha.com'
};

export default AppConfig;
